import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  UsersIcon,
  UserGroupIcon,
  DocumentTextIcon,
  ArchiveBoxIcon,
  ChatBubbleBottomCenterTextIcon,
  QrCodeIcon,
  ChartPieIcon,
} from '@heroicons/react/20/solid';
import {
  ArrowLeftOnRectangleIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline';

import { Auth } from 'aws-amplify';
import { useAuth } from '../../../context';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const tabs = [
  {
    id: 1,
    name: 'Users',
    icon: UsersIcon,
    link: '/admin/users',
  },
  {
    id: 2,
    name: 'Quotation',
    icon: DocumentTextIcon,
    link: '/admin/quotations',
  },
  {
    id: 3,
    name: 'Clients',
    icon: UserGroupIcon,
    link: '/admin/clients',
  },
  {
    id: 4,
    name: 'Products',
    icon: ArchiveBoxIcon,
    link: '/admin/products',
  },
  {
    id: 5,
    name: 'Feedback',
    icon: ChatBubbleBottomCenterTextIcon,
    link: '/admin/feedbacks',
  },
  {
    id: 6,
    name: 'QR Generator',
    icon: QrCodeIcon,
    link: '/admin/qr-generator',
  },
  {
    id: 7,
    name: 'Statistics',
    icon: ChartPieIcon,
    link: '/admin/statistics',
  },
  {
    id: 8,
    name: 'Settings',
    icon: Cog6ToothIcon,
    link: '/admin/settings',
  },
];

function DashboardTab(props) {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  async function logout() {
    try {
      await Auth.signOut({ global: true });
      signOut();
      navigate('/');
    } catch (error) {}
  }
  return (
    <ul
      aria-orientation='vertical'
      className='xl:pt-12 lg:pt-8 md:pt-12 sm:pt-8 pt-8'
    >
      {tabs.map((t) => (
        <Link to={t.link}>
          <li
            className={classNames(
              props.selectedTab === t.id
                ? 'text-blue-800 bg-blue-50 border-l-4 border-blue-800'
                : 'text-gray-600 hover:text-blue-800 hover:bg-gray-100  focus:text-indigo-700 focus:outline-none',
              'pl-6 cursor-pointer xl:text-md lg:text-md md:text-md sm:text-sm text-sm leading-3 tracking-normal mb-2 xl:py-4 lg:py-3 md:py-4 sm:py-3 py-3 ml-4'
            )}
          >
            <div className='flex items-center'>
              <div>
                <t.icon className='xl:h-6 lg:h-5 md:h-6 sm:h-5 h-5 xl:w-6 lg:w-5 md:w-6 sm:w-5 w-5' />
              </div>
              <span className='ml-4'>{t.name}</span>
            </div>
          </li>
        </Link>
      ))}
      <li className='fixed bottom-0 flex justify-start items-center ml-4 sm:justify-end sm:mr-4'>
        <button
          onClick={logout}
          className='flex items-center gap-x-3 rounded-md p-2 text-lg font-semibold leading-6 text-blue-800'
        >
          <ArrowLeftOnRectangleIcon
            className='h-6 w-6 shrink-0 text-blue-800'
            aria-hidden='true'
          />
          Logout
        </button>
      </li>
    </ul>
  );
}

export default DashboardTab;
