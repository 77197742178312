import React, { useState, useEffect } from "react";
import Dashboard from "../../components/Admin/Dashboard/Dashboard";
import { useClientsContext } from "../../context/ClientsContext";
import SlideOvers from "../../components/Admin/Content/SlideOvers";
import ViewCreateClient from "../../Views/AdminFeatures/SlideOvers/Clients/ViewCreateClient";

function ViewAdminClients() {
  const [modalOpen, setModalOpen] = useState(false);
  const [tableData,setTableData]= useState([])

  const viewSlide = () => {
    setModalOpen(true);
  };

  const { clients, getAllClients } = useClientsContext();
  useEffect(() => {
    getAllClients();
    setTableData(clients)
  }, []);

  useEffect(() => {
    setTableData(clients)
  }, [clients]);

  function handleSearch(e) {
    if (e.target.value === "") {
      getAllClients();
    }

    const filteredData = clients.filter((row) => {
      return row["firstName"]
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setTableData(filteredData)
  }

  const columns = [
    { name: "FIRSTNAME", selector: "firstName" },
    { name: "LASTNAME", selector: "lastName" },
    { name: "COMPANY NAME", selector: "companyName" },
    { name: "CONTACT NUMBERS", selector: "contactNumber1" },
    { name: "EMAIL ADDRESS", selector: "email" },
    { name: "ACTIONS", selector: "actions" },
  ];

  return (
    <div>
      <Dashboard
        tab={3}
        title="Clients"
        search="Search clients..."
        button="Create New Client"
        viewSlide={viewSlide}
        columns={columns}
        data={tableData}
        handleSearch={handleSearch}
      />
      <div>
        {modalOpen && (
          <SlideOvers
            content={<ViewCreateClient setOpenModal={setModalOpen} />}
          />
        )}
      </div>
    </div>
  );
}

export default ViewAdminClients;
