import axios from 'axios'
import { api } from '../apis/configs/axiosConfig'
import { defineCancelApiObject } from '../apis/configs/axiosUtils'

export const AnalyticsAPI = {
  getUserState: async function (body) {
    const headers = {
      Accept: 'application/json',
      'x-api-key': process.env.REACT_APP_XAPI_KEY,
      'x-sessionId': localStorage.getItem('sessionId'),
    }

    const response = axios
      .post(`${process.env.REACT_APP_USER_ANALYTICS}`, body, {
        headers: headers,
      })
      .then((resp) => {
        return resp
      })
    return response
  },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(AnalyticsAPI)
