import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { AnalyticsAPI } from '../../apis/analyticsAPI'

function EmptyCart() {
  function callAnalyticAPI(status) {
    const body = {
      state: status,
      hasPurchasedItems: false,
      hasVisited: window.innerWidth <= 768 ? "exhibition" : "website",
      recordedDateTime: (new Date()).toISOString(),
    }
    AnalyticsAPI.getUserState(
      body
    )
      .then((response) => {})
      .catch((error) => {})
  }

  useEffect(() => {
    callAnalyticAPI('User is on Quote page with NO product added to cart')
  }, [])

  return (
    <div>
      <section className="bg-white ">
        <div className="container px-6 py-8 mx-auto text-center">
          <div className="max-w-lg mx-auto">
            <h1 className="text-3xl font-semibold text-gray-800  lg:text-4xl">
              The cart is empty!
            </h1>

            <button className="py-4 px-6 mt-12 text-sm font-medium leading-5 text-center text-white capitalize bg-blue-800 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-indigo-200 lg:mx-0 lg:w-auto focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
              <Link to="/products">View Products</Link>
            </button>
          </div>

          <div className="flex justify-center mt-10">
            <img
              alt="Empty Cart"
              className="object-cover w-full h-96 rounded-xl lg:w-4/5"
              src="https://adi-images.s3.us-east-2.amazonaws.com/images/emptyCart.jpg"
            />
          </div>
        </div>
      </section>
    </div>
  )
}

export default EmptyCart
