import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import axios from 'axios'

const invitationSchema = yup.object().shape({
  code: yup.string().required('Invitation Code is required'),
})

export default function InvitationCode() {
  const [invalidCode, setInvalidCode] = useState(false)
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(invitationSchema) })

  const onSubmitHandler = async (data) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/invite-code`,
        JSON.stringify(data),
        {
          headers: { 'x-api-key': process.env.REACT_APP_X_API_KEY },
        },
      )
      localStorage.setItem('code', true)
      window.location.reload()
    } catch (error) {
      setInvalidCode(true)
      setTimeout(() => {
        setInvalidCode(false)
        reset()
      }, 3000)
    }
  }

  return (
    <div>
      <div
        className="fixed bg-cover md:h-screen h-screen  w-full  bg-center xl:py-10 lg:py-1 md:py-20 md:bg-none sm:py-2 xs:py-24 py-1 "
        style={{
          backgroundImage: `url(https://adi-images.s3.us-east-2.amazonaws.com/images/home.jpg)`,
        }}
      >
        <div
          className="absolute  hidden xl:flex md:flex  w-full  lg:absolute md:absolute lg:right-0 md:right-0 xl:px-2 xl:w-[400px] md:w-[420px] lg:w-[230px] px-5 my-1.5 bg-blue-200 rounded-md mr-5 space-x-4 "
          role="alert"
        >
          <svg
            aria-hidden="true"
            className="flex-shrink-0 w-5 h-5 text-blue-900 xl:mt-5 xl:ml-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <div className="ml-3 text-sm font-medium text-blue-900 px-1 py-2">
            <span className="text-md ">Please Contact us via </span>
            <span className=" font-bold ">info@artdecoint.com</span> to request
            the Invitation Code.
          </div>
        </div>
        <div className="w-full max-w-xl p-6 mx-auto sm:my-auto bg-white rounded-lg shadow-md xl:mt-0 lg:mt-0 md:mt-[120px]">
          <img
            src="https://adi-images.s3.us-east-2.amazonaws.com/images/logo.JPG"
            className="mx-auto lg:h-32 md:h-24 sm:h-24 h-24"
            alt="Artdecoint Logo"
          />

          <h1 className="text-2xl mt-4 font-semibold text-center text-blue-900">
            Art Decoration International (Pvt) Ltd
          </h1>
          <hr className="w-3/4 h-1 mx-auto my-4 bg-blue-900 border-0 rounded " />
          <h1 className="text-xl mt-16 text-gray-600">
            Please Enter the Invitation Code to Continue :
          </h1>

          <form onSubmit={handleSubmit(onSubmitHandler)} className="mt-4">
            <div>
              <input
                className="bg-white appearance-none border-2 border-gray-200 rounded w-full md:gap-y-2
            py-3 px-4  text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-900"
                id="code"
                name="code"
                type="text"
                placeholder="Invitation code"
                {...register('code')}
              />
            </div>
            <div>
              {invalidCode && (
                <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs mt-4">
                  Sorry! The code you entered is invalid!
                </p>
              )}
              <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs mt-4">
                {errors.code?.message}
              </p>
            </div>
            <div className="flex justify-end">
              <a
                href="/registration"
                className="text-md font-semibold text-blue-900"
              >
                Are you a New Client ?
              </a>
            </div>

            <div className="pt-4 my-12 items-center">
              <button
                type="submit"
                className="w-full mt-4 text-white bg-black hover:bg-gray-800 focus:ring-4 focus:ring-gray-500 font-medium text-sm px-5 py-4 mr-4 focus:outline-none "
              >
                Submit
              </button>
              <Link to="/">
                <button className="w-full mt-6 text-white bg-black hover:bg-gray-800 focus:ring-4 focus:ring-gray-500 font-medium text-sm px-5 py-4 mr-4 focus:outline-none ">
                  Close
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
