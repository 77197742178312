import React, { useEffect, useState } from 'react'
import { useStatisticsContext } from '../../../context/StatisticsContext'
import Graph from './Graph'
import { FunnelIcon } from '@heroicons/react/24/outline'

export default function Analytics() {
  const [selectedFilter, setSelectedFilter] = useState('1-month')

  const { data, getWebSiteVisitorCountByMonths } = useStatisticsContext()

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value)
    getWebSiteVisitorCountByMonths(event.target.value)
  }

  useEffect(() =>{
    getWebSiteVisitorCountByMonths(selectedFilter)
  }, [])

  return (
    <div className="overflow-x-auto sm:mx-2 md:mx-4 lg:mx-4 xl:block lg:block md:block hidden">
      <div className="inline-block min-w-full py-2 align-middle">
        <div className="overflow-hidden">
          <div className="flex flex-col mt-6 lg:mx-auto">
            <div className="overflow-x-auto max-w-full sm:mx-2 md:mx-4 lg:mx-4 xl:block lg:block md:block hidden">
              <div class="flex items-center lg:gap-x-3 md:gap-x-3 sm:gap-x-2 gap-x-2">
                <h2 class="lg:text-lg md:text-base sm:text-sm text-sm font-medium text-gray-800 ">
                  Project Analytics
                </h2>

                <span className="lg:px-3 md:px-3 sm:px-2 px-2 py-1 text-xs text-blue-600 bg-blue-100 rounded-full">
                  <div className="flex h-6 items-center">
                    <div className="ml-2">
                      Count of clients who visited the website
                    </div>
                  </div>
                </span>
              </div>
              <div className="flex justify-end ml-2">
                <div className="relative">
                  <select
                    value={selectedFilter}
                    onChange={handleFilterChange}
                    className="block p-2 pr-8 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  >
                    <option value="">Select Filter</option>
                    <option value="1-month">Last 1 Month</option>
                    <option value="3-month">Last 3 Months</option>
                    <option value="6-month">Last 6 Months</option>
                    <option value="9-month">Last 9 Months</option>
                    <option value="12-month">Last 12 Months</option>
                  </select>
                  <div className="absolute inset-y-0 right-2 flex items-center px-2 pointer-events-none">
                    <FunnelIcon className="h-4 w-4 text-gray-400" />
                  </div>
                </div>
              </div>
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="overflow-hidden border border-gray-200 md:rounded-lg">
                  <Graph />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
