import React from 'react'
import { Link } from 'react-router-dom'
import background from '../../assets/home.jpg'

function Home() {
  return (
    <div
      className="bg-cover"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <div className="overflow-hidden relative ">
        <div className="text-start lg:w-2/5 md:w-full sm:w-full lg:my-20 md:my-16 sm:my-16 my-16 py-8 lg:py-20 lg:px-16 md:px-12 px-4 sm:px-8 z-20">
          <h2 className="lg:text-4xl md:text-3xl sm:text-2xl text-2xl font-extrabold text-black">
            <span className="block">Feel the magic of Art decor</span>
          </h2>
          <p className="lg:text-xl md:text-xl sm:text-lg text-md mt-8 text-gray-800 text-shadow-xl">
            To provide a wide variety of design choices to suit every home, it
            is crucial to focus on Detail, Design, Quality, and Sustainability.
          </p>
          <div className="lg:mt-0 lg:flex-shrink-0">
            <div className="mt-12 inline-flex rounded-md shadow">
              <button
                type="button"
                className="py-4 px-6  bg-blue-800 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              >
                <Link to="/products"> Discover More</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
