import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Color from '../../../../components/Admin/Content/Color';
import { useProductsContext } from '../../../../context/ProductsContext';
import Loading from '../../Auth/Loading';
import ImageCard from '../../../../components/Shared/ImageCard';
import ProductImgs from '../../../../components/ProductDetails/ProductImgs';

function ViewProduct({ productId, onClose }) {
  const [product, setProduct] = useState(null);

  const { getProductByID } = useProductsContext();

  useEffect(() => {
    const fetchProduct = async () => {
      const productData = await getProductByID(productId);
      setProduct(productData);
    };

    fetchProduct();
  }, []);
  if (!product) {
    return <Loading />;
  }

  /**
   * return no-image if the images are missing in the product
   * @param images
   * @returns {string[]|*}
   */
  const getImages = (images) => {
    if (images && images.length > 0) {
      return images;
    } else {
      return [
        'https://adi-images.s3.us-east-2.amazonaws.com/products/images/no-image.jpeg',
      ];
    }
  };

  return (
    <form className='flex h-full flex-col divide-y divide-gray-200 bg-white shadow-4xl'>
      <div className='flex justify-between text-gray-900 text-3xl px-4 sm:px-6 pb-5 pt-6'>
        <b>Product details</b>
        <XMarkIcon
          onClick={() => onClose()}
          className='h-10 w-10 text-gray-500 cursor-pointer'
        />
      </div>
      <div className='flex-1 h-screen overflow-y-auto overflow-x-hidden'>
        <div className='flex flex-1 flex-col justify-between'>
          <div className='divide-y divide-gray-200 px-4 sm:px-6'>
            <div className='space-y-6 pb-5 pt-6'>
              {product.map((p) => (
                <div className='space-y-12'>
                  <div>
                    <div className=' lg:mx-auto lg:container flex justify-center'>
                      <div className={'w-3/4'}>
                        <ProductImgs image={getImages(p.image_url.images)} />
                      </div>
                    </div>
                    <div class='container mx-auto grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2  grid-cols-2 pt-2 lg:gap-3 gap-3'>
                      <div class='h-12 text-md'>
                        <label className='self-center text-md font-base whitespace-nowrap text-gray-500'>
                          Item ID
                        </label>
                      </div>
                      <div className='h-12'>
                        <label>{p.PK}</label>
                      </div>
                      <div className='h-12 text-md'>
                        <label className='self-center text-md font-base whitespace-nowrap text-gray-500'>
                          Item Name
                        </label>
                      </div>
                      <div className='h-12 text-md'>
                        <label>{p.SK}</label>
                      </div>
                      <div className='h-12 text-md'>
                        <label className='self-center text-md font-base whitespace-nowrap text-gray-500 break-words'>
                          Dimensions (CM)
                        </label>
                      </div>
                      <div className='h-12 text-md'>
                        <label>
                          {p.dimensions.length} x {p.dimensions.width} x
                          {p.dimensions.height}
                        </label>
                      </div>
                      <div className='h-12 text-md'>
                        <label className='self-center text-md font-base whitespace-nowrap text-gray-500'>
                          Default Colors
                        </label>
                      </div>
                      <div className='h-12 text-md'>
                        <Color color={p.color} />
                      </div>
                      <div className='h-12 text-md'>
                        <label className='self-center text-md font-base whitespace-nowrap text-gray-500'>
                          Available Quantity
                        </label>
                      </div>
                      <div className='h-12 text-md'>
                        <label>{p.quantity}</label>
                      </div>
                      <div className='h-12 text-md'>
                        <label className='self-center text-md font-base whitespace-nowrap text-gray-500'>
                          Customization
                        </label>
                      </div>
                      <div className='h-12 text-md'>
                        <label className='text-green-500 border-solid border-2 border-green-500 rounded-lg px-5 py-1'>
                          Yes
                        </label>
                      </div>
                      <div className='h-12 text-md'>
                        <label className='self-center text-md font-base whitespace-nowrap text-gray-500'>
                          Description
                        </label>
                      </div>
                    </div>

                    <div className='h-12 w-full text-md right-1'>
                      <label>{p.description}</label>
                    </div>
                    <div className='lg:mx-auto lg:container flex justify-center'>
                      {p.qrCodeImageUrl ? (
                        <ImageCard image={p.qrCodeImageUrl} />
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-shrink-0 justify-end px-4 py-4'>
        <button
          type='button'
          className='ml-4 py-2 px-2 lg:w-1/6 md:w-1/3 sm:w-full w-full bg-gray-800 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center lg:text-base md:text-base sm:text-sm text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg'
          onClick={() => onClose()}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

export default ViewProduct;
