import React, { useEffect, useState } from "react";
import Dashboard from "../../components/Admin/Dashboard/Dashboard";
import ViewAddNewProduct from "./SlideOvers/Products/ViewAddNewProduct";
import SlideOvers from "../../components/Admin/Content/SlideOvers";
import { useProductsContext } from "../../context/ProductsContext";

function ViewAdminProducts() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [tableData,setTableData]= useState([])
  const viewSlide = () => {
    setModalOpen(true);
  };
  const { products, getAllProducts, getProductsByType } = useProductsContext();

  useEffect(() => {
    getAllProducts();
    setTableData(products)
  }, []);

  useEffect(() => {
    setTableData(products)
  }, [products]);

  function handleSearch(e) {
    if (e.target.value === "") {
      if (selectedType === 'all' || !selectedType ) {
        getAllProducts();
      } else {
        getProductsByType(selectedType);
      }
    }

    const filteredData = products.filter((row) => {
      return row["PK"]
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setTableData(filteredData)
  }

  const handleFilter = (type) => {
    setSelectedType(type);
    if (type === 'all') {
      getAllProducts();
    } else {
      getProductsByType(type);
    }
  };

  const columns = [
    { name: "ITEM ID", selector: "PK" },
    { name: "ITEM NAME", selector: "SK" },
    { name: "DIMENSIONS (L X H X W)", selector: "dimensions" },
    { name: "DEFAULT COLORS", selector: "colors" },
    { name: "ACTIONS", selector: "actions" },
  ];

  return (
    <div>
      <Dashboard
        tab={4}
        title="Products"
        search="Search products..."
        button="Add New product"
        viewSlide={viewSlide}
        columns={columns}
        data={tableData}
        handleFilter={handleFilter}
        selectedType={selectedType}
        handleSearch={handleSearch}
      />
      <div>
        {modalOpen && (
          <SlideOvers
            content={<ViewAddNewProduct setOpenModal={setModalOpen} />}
          />
        )}
      </div>
    </div>
  );
}

export default ViewAdminProducts;
