import React from 'react'
import { SpinnerCircular } from 'spinners-react'

function Loading() {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center">
      <div>
        <SpinnerCircular color="#3F70FD" />
        <p class="mt-4 text-gray-600 italic">loading...</p>
      </div>
    </div>
  )
}

export default Loading
