import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import Filter from './Filter'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Table(props) {
  

  return (
    <section className="container px-4 lg:py-8 md:py-4 sm:py-4 py-4 mx-auto">
      <div className="flex justify-between">
        <div
          class={classNames(
            props.selectedTab === 6 ? 'hidden' : 'relative',
            'flex items-center lg:gap-x-3 md:gap-x-3 sm:gap-x-2 gap-x-2',
          )}
        >
          <h2 class="lg:text-lg md:text-base sm:text-sm text-sm font-medium text-gray-800 ">
            All {props.title} Count
          </h2>

          <span className="lg:px-3 md:px-3 sm:px-2 px-2 py-1 text-xs text-blue-600 bg-blue-100 rounded-full">
            {props.data.length}
          </span>
        </div>
        {props.selectedTab === 4 || props.selectedTab === 6 ? (
          <div className="flex justify-end ml-2">
            <Filter
              name={'Product Type'}
              options={[
                { label: 'All', value: 'all' },
                { label: 'New Arrivals', value: 'new' },
                { label: 'Running', value: 'running' },
              ]}
              handleFilter={props.handleFilter}
            />
          </div>
        ) : null}
      </div>
      <div className="flex flex-col mt-6 lg:mx-auto">
        <div className="mx-2 -my-2 overflow-x-auto sm:mx-2 md:mx-4 lg:mx-4 xl:block lg:block md:block hidden">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden border border-gray-200  md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-gray-50 ">
                  <tr>
                    {props.columns.map((c) => (
                      <th
                        scope="col"
                        className="px-4 py-3.5 lg:text-sm md:text-sm sm:text-xs text-xs font-normal text-center rtl:text-right text-gray-500 "
                      >
                        {c.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 ">
                  {props.currentItems.map((d) => (
                    <tr>
                      {props.columns.map((c, index) => (
                        <td
                          key={index}
                          className="px-4 py-4 lg:text-sm md:text-sm sm:text-xs text-xs text-gray-500  whitespace-nowrap"
                        >
                          {d[c.selector]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 xl:hidden lg:hidden md:hidden">
          {props.currentItems.map((d) => (
            <div className="bg-white p-4 rounded-lg shadow divide-y ">
              {props.columns.map((c) => (
                <div className="py-3">
                  <p className="text-xs text-gray-400">{c.name}</p>
                  <p className="text-sm leading-none text-justify text-gray-800 mt-2">
                    {d[c.selector]}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div className="flex items-center justify-center mt-6">
        <ReactPaginate
          previousLabel={
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="lg:w-5 md:w-5 sm:w-4 w-4 lg:h-5 md:h-5 sm:h-4 h-4  rtl:-scale-x-100"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                />
              </svg>

              <span>previous</span>
            </>
          }
          nextLabel={
            <>
              <span>Next</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="lg:w-5 md:w-5 sm:w-4 w-4 lg:h-5 md:h-5 sm:h-4 h-4 rtl:-scale-x-100"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </>
          }
          breakLabel={'...'}
          pageCount={props.pageCount}
          marginPagesDisplayed={3}
          pageRangeDisplayed={3}
          onPageChange={props.handlePageClick}
          containerClassName={'flex items-center gap-x-3'}
          pageLinkClassName={
            'hidden lg:block px-2 py-1 text-sm text-gray-500 rounded-md  hover:bg-gray-100'
          }
          previousLinkClassName={
            'flex items-center px-3 py-1.5 lg:text-sm md:text-sm sm:text-xs text-xs text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100'
          }
          nextLinkClassName={
            'flex items-center px-3 py-1.5 lg:text-sm md:text-sm sm:text-xs text-xs text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100'
          }
          breakLinkClassName={
            'hidden lg:block px-2 py-1 text-sm text-gray-500 rounded-md  hover:bg-gray-100'
          }
          activeLinkClassName={
            'px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60'
          }
          renderOnZeroPageCount={null}
        />
      </div>
    </section>
  )
}

export default Table
