import React, { useEffect, useState } from 'react'
import { Route, Routes as Switch, useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { useAuth } from '../../../context'
import ViewAdminClients from '../ViewAdminClients'
import ViewAdminFeedbacks from '../ViewAdminFeedbacks'
import ViewAdminProducts from '../ViewAdminProducts'
import ViewAdminQRGenerator from '../ViewAdminQRGenerator'
import ViewAdminQuotations from '../ViewAdminQuotations'
import ViewAdminStatistics from '../ViewAdminStatistics'
import ViewAdminUsers from '../ViewAdminUsers'
import ViewAdminSettings from '../ViewAdminSettings'
import Alert from '../../../components/Admin/Content/Alert'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ProductsProvider } from '../../../context/ProductsContext'
import { ClientsProvider } from '../../../context/ClientsContext'
import { UsersProvider } from '../../../context/UserContext'
import { StatisticsProvider } from '../../../context/StatisticsContext'
import { FeedbacksProvider } from '../../../context/FeedbackContext'
import { QuotationsProvider } from '../../../context/QuotationContex'

function AdminRoutes() {
  const [showAlert, setShowAlert] = useState(false)
  const navigate = useNavigate()
  const { signOut } = useAuth()

  useEffect(() => {
    const checkTokenExpiration = async () => {
      try {
        const session = await Auth.currentSession()
        const expirationTime = session.getIdToken().getExpiration()
        const currentTime = Math.floor(Date.now() / 1000) // Current time in seconds

        const remainingTime = expirationTime - currentTime // Remaining time in seconds

        const alertThreshold = 10 // Alert threshold in seconds
        const logoutThreshold = 1 // Logout threshold in seconds

        if (remainingTime <= alertThreshold) {
          setShowAlert(true)

          if (remainingTime <= logoutThreshold) {
            try {
              signOut()
            } catch (error) {}
          }
        }
      } catch (error) {}
    }

    checkTokenExpiration() // Check token expiration immediately upon component mount

    const interval = setInterval(checkTokenExpiration, 1000) // Check every second

    return () => clearInterval(interval)
  }, [navigate, signOut])

  return (
    <>
      <Switch>
        <Route
          path="/users"
          element={
              <UsersProvider>
                <ViewAdminUsers />
              </UsersProvider>
          }
        />
        <Route
          path="/quotations"
          element={
              <QuotationsProvider>
                <ViewAdminQuotations />
              </QuotationsProvider>
          }
        />
        <Route
          path="/clients"
          element={
              <ClientsProvider>
                <ViewAdminClients />
              </ClientsProvider>
          }
        />
        <Route
          path="/products"
          element={
            <ProductsProvider>
              <ViewAdminProducts />
            </ProductsProvider>
          }
        />
        <Route
          path="/feedbacks"
          element={
              <FeedbacksProvider>
                <ViewAdminFeedbacks />
              </FeedbacksProvider>
          }
        />
        <Route
          path="/qr-generator"
          element={
            <ProductsProvider>
              <ViewAdminQRGenerator />
            </ProductsProvider>
          }
        />
        <Route
          path="/statistics"
          element={
              <StatisticsProvider>
                <ViewAdminStatistics />
              </StatisticsProvider>
          }
        />
        <Route
          path="/settings"
          element={
              <ViewAdminSettings />
          }
        />
      </Switch>
      {showAlert && (
        <Alert
          icon={ExclamationTriangleIcon}
          bgColor="fixed top-0 right-0 border-2 border-yellow-600 mt-4 sm:mr-1 rounded-md bg-yellow-50 p-4"
          iconColor="h-5 w-5 text-yellow-400"
          text={
            <p className="text-center">
              Your session timed out, you will be redirected to the login screen
              in a few seconds. Please wait for redirection. Thank you!
            </p>
          }
          buttonColor="inline-flex rounded-md bg-yellow-50 p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
          icon2={XMarkIcon}
        />
      )}
    </>
  )
}

export default AdminRoutes
