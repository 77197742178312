import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { AnalyticsAPI } from '../../apis/analyticsAPI'
import axios from "axios";

function Products(props) {
  useEffect(() => {
    callAnalyticAPI('On Products Page')
      async function fetchData() {
          try {
              const response = await axios.get(
                  `${process.env.REACT_APP_API_URL}/products-by-type/${props.productType}`,
                  {
                      headers: {'x-api-key': process.env.REACT_APP_X_API_KEY},
                  },
              )
              props.setProducts(response.data);
              props.setSearchProducts(response.data);
          } catch (error) {
              console.log(error);
          }
      }

      fetchData();
  }, [])

  function callAnalyticAPI(status) {
    const body = {
        state: status,
        hasPurchasedItems: false,
        hasVisited: window.innerWidth <= 768 ? "exhibition" : "website",
        recordedDateTime: (new Date()).toISOString(),
    }
    AnalyticsAPI.getUserState(
      body
    )
      .then((response) => {})
      .catch((error) => {})
  }

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl py-12 px-4  sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="lg:text-md md:text-md sm:text-sm text-sm tracking-tight text-gray-400">
          {props.searchProducts.length} items
        </h2>
        {props.searchProducts?.length ? (
          <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-6">
            {props.searchProducts.map((p) => (
              <div key={p.PK} className="group relative">
                <div className="min-h-80 aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:aspect-none lg:h-80">
                  <img
                    src={
                      p?.image_url?.images && p?.image_url?.images.length > 0
                        ? p?.image_url?.images[0]
                        : ''
                    }
                    alt={
                      p?.image_url?.images && p?.image_url?.images.length > 0
                        ? p.SK
                        : ''
                    }
                    className="h-[360px] w-[600px] object-cover lg:h-full lg:w-full"
                  />
                </div>
                <div className="mt-4 flex justify-between">
                  <div className="hover:cursor-pointer">
                    <h3 className="text-sm text-gray-700">
                      <Link to={'/products/' + p.PK}>
                        <span aria-hidden="true" className="absolute inset-0" />
                        {p.SK}
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Products
