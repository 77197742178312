import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useState, useEffect } from "react";
import { useProductsContext } from "../../../../context/ProductsContext";
import Loading from "../../Auth/Loading";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ViewEditColorVarient({ onClose, productId }) {
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [color, setColor] = useState(null);
  const [error, setError] = useState(null);
  const [newColor, setNewColor] = useState(null);
  const [newFinish, setNewFinish] = useState(null);

  const { getProductByID, addNewColor, removeColor } = useProductsContext();
  useEffect(() => {
    const fetchProduct = async () => {
      const productData = await getProductByID(productId);
      setColor(productData[0].color);
    };

    fetchProduct();
  }, []);
  if (!color) {
    return <Loading />;
  }

  const handleRemove = async (index) => {
    try {
      await removeColor(productId, index);
      setTimeout(() => {
        onClose();
      }, 1000);
    } catch (error) {}
  };
  const handleClick = () => {
    setIsCodeVisible(true);
  };

  const handleSubmit = async () => {
    if (!newColor) {
      setError("* item color is required!");
      setTimeout(() => {
        setError(null);
      }, 3000);
    } else if (!newFinish) {
      setError("* item finish is required!");
      setTimeout(() => {
        setError(null);
      }, 3000);
    } else {
      const updatedData = {
        item_color: newColor,
        color_finish: newFinish,
      };
      try {
        await addNewColor(productId, updatedData);
        setIsCodeVisible(false);
        setTimeout(() => {
          onClose();
          setNewColor(null);
          setNewFinish(null);
          setError(null);
        }, 1000);
      } catch (error) {}
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full">
              <tbody className="bg-white">
                <Fragment>
                  <tr className="border-t border-gray-200">
                    <th
                      colSpan={3}
                      scope="colgroup"
                      className="bg-gray-50 py-2 pl-4 pr-3 text-left text-2xl font-semibold text-gray-900 sm:pl-3"
                    >
                      {productId}
                    </th>
                  </tr>

                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                    >
                      Item Color
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Color Finish
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                    ></th>
                  </tr>

                  {color.map((Color, ColorIdx) => (
                    <tr
                      key={ColorIdx}
                      className={classNames(
                        ColorIdx === 0 ? "border-gray-300" : "border-gray-200",
                        "border-t"
                      )}
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                        {Color.item_color}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {Color.color_finish}
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                        <button className="text-indigo-600 hover:text-indigo-900">
                          <XMarkIcon
                            onClick={() => handleRemove(ColorIdx)}
                            className="h-6 w-6 text-red-500 cursor-pointer"
                          />
                          <span className="sr-only"> {productId}</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </Fragment>
              </tbody>
            </table>
            {isCodeVisible && (
              <div>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                  <div className="sm:col-span-2">
                    <div className="relative">
                      <label
                        htmlFor="name"
                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                      >
                        Item Color
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="  Item Color"
                        onChange={(e) => setNewColor(e.target.value)}
                      />
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div className="sm:col-span-2">
                    <div className="relative">
                      <label
                        htmlFor="name"
                        className="absolute -top-2 left-2 inline-block bg-white px- text-xs font-medium text-gray-900"
                      >
                        Color finish
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="     Color finish"
                        onChange={(e) => setNewFinish(e.target.value)}
                      />
                    </div>
                  </div>
                </td>
                {error && (
                  <p className="text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs">
                    {error}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end border-t border-gray-900/10  px-4 py-4">
        <button
          type="button"
          className="ml-4 py-2 lg:w-1/4 md:w-1/2 sm:w-full w-full bg-gray-400 hover:bg-gray-500 focus:ring-gray-600 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center lg:text-base md:text-base sm:text-sm text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
          onClick={() => onClose()}
        >
          Close
        </button>

        <button
          type="submit"
          onClick={() => (isCodeVisible ? handleSubmit() : handleClick())}
          className="ml-4 py-2 lg:w-1/4 md:w-1/3 sm:w-full w-full bg-blue-800 hover:bg-blue-500 focus:ring-blue-700 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center lg:text-base md:text-base sm:text-sm text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
        >
          Add Color
        </button>
      </div>
    </div>
  );
}
export default ViewEditColorVarient;
