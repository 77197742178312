import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const tabs = [
  {
    id: 1,
    name: 'Home',
    link: '/',
  },
  {
    id: 2,
    name: 'Products',
    link: '/products',
  },
  {
    id: 3,
    name: 'New Arrivals',
    link: '/arrivals',
  },
  {
    id: 4,
    name: 'Contact Us',
    link: '/contactus',
  },
  {
    id: 5,
    name: 'Login',
    link: '/login',
  },
]

function Header(props) {
  const [mdOptionsToggle, setMdOptionsToggle] = useState(true)
  const [showMenu, setShowMenu] = useState(false)
  const qty = useSelector((state) => state.cart.cartTotalQuantity)

  return (
    <div className="bg-white">
      <div>
        <div className="relative">
          <div className=" fixed shadow-md  z-50 w-full bg-white flexbg-white px-6 lg:py-3 md:py-6 sm:py-4 py-3">
            <div className="container mx-auto flex items-center justify-between">
              <h1 className="md:w-2/12 text-gray-800">
                <img
                  className="lg:w-16 md:w-14 sm:w-12 w-12"
                  src="https://adi-images.s3.us-east-2.amazonaws.com/images/logo.JPG"
                  alt="logo"
                />
                <span className="lg:block md:hidden sm:hidden hidden self-center lg:text-md md:text-xs sm:text-xs text-xs font-base whitespace-nowrap text-blue-900">
                  Art Decoration International (Pvt) Ltd
                </span>
              </h1>
              <ul className="hidden w-8/12 md:ml-8 md:flex items-center justify-center lg:space-x-16 md:space-x-8 space-x-16">
                {tabs.map((t) => (
                  <Link key={t.id} to={t.link}>
                    <li
                      className={classNames(
                        props.page === t.id
                          ? 'text-blue-900 font-semibold'
                          : 'text-gray-600 hover:text-gray-900',
                        'text-base focus:text-gray-400 ',
                      )}
                    >
                      {t.name}
                    </li>
                  </Link>
                ))}
              </ul>
              <div className="md:w-2/12 justify-end flex items-center space-x-4 xl:space-x-8">
                <div className="hidden lg:flex md:flex items-center space-x-4 xl:space-x-8">
                  <Link to={'/quote'}>
                    <p
                      aria-label="go to cart"
                      className={classNames(
                        props.page === 6
                          ? 'text-blue-900 font-semibold'
                          : 'text-gray-600 hover:text-gray-900',
                        'flex text-base focus:text-gray-400 ',
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                        />
                      </svg>

                      <p className="pl-2">Quote ({qty})</p>
                    </p>
                  </Link>
                </div>
                <div className="flex lg:hidden md:hidden">
                  <Link to={'/quote'}>
                    <p
                      aria-label="go to cart"
                      className={classNames(
                        props.page === 6
                          ? 'text-blue-900 font-semibold'
                          : 'text-gray-600 hover:text-gray-900',
                        'flex text-base focus:text-gray-400 mr-4',
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                        />
                      </svg>

                      <p>({qty})</p>
                    </p>
                  </Link>
                  <button
                    aria-label="show options"
                    onClick={() => setMdOptionsToggle(!mdOptionsToggle)}
                    className="text-gray-600 hover:text-gray-900 hidden md:flex focus:outline-none focus:ring-2 rounded focus:ring-gray-400"
                  >
                    <svg
                      className="fill-stroke"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 6H20"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 12H20"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6 18H20"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    aria-label="open menu"
                    onClick={() => setShowMenu(true)}
                    className="text-gray-600 hover:text-gray-900 md:hidden focus:outline-none focus:ring-2 rounded focus:ring-gray-400"
                  >
                    <svg
                      className="fill-stroke"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 6H20"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 12H20"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6 18H20"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="mobile-menu"
            className={`${
              showMenu ? 'flex' : 'hidden'
            } absolute  z-30 inset-0 md:hidden bg-white flex-col h-screen w-full`}
          >
            <div className="flex items-center justify-between  pb-4 p-4">
              <button
                onClick={() => setShowMenu(false)}
                aria-label="close menu"
                className="focus:outline-none focus:ring-2 rounded focus:ring-gray-600"
              >
                <svg
                  className="fill-stroke text-gray-600"
                  width={16}
                  height={16}
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 4L4 12"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 4L12 12"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-6 px-6 py-4">
              <ul className="flex flex-col space-y-6">
                {tabs.map((t) => (
                  <Link key={t.id} to={t.link}>
                    <li
                      className={classNames(
                        props.page === t.id
                          ? 'text-blue-900 font-semibold'
                          : 'text-gray-600 hover:text-gray-900',
                        'flex items-center justify-between text-lg ',
                      )}
                    >
                      {t.name}
                    </li>
                  </Link>
                ))}
              </ul>
              <ul className="flex flex-col space-y-8 bg-white w-full py-8 ">
                <Link to={'/quote'}>
                  <p
                    className={classNames(
                      props.page === 5
                        ? 'text-blue-900 font-semibold'
                        : 'text-gray-600 hover:text-gray-900',
                      'flex items-center space-x-2 focus:text-gray-400 ',
                    )}
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                        />
                      </svg>
                    </div>
                    <p className="text-base">Quote ({qty})</p>
                  </p>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
