import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { FunnelIcon } from '@heroicons/react/20/solid'
import Products from './Products'
import Searchbar from './Searchbar'
import axios from "axios";

const sizes = [
  { id: 1, label: 'S', value: 'small' },
  { id: 2, label: 'M', value: 'medium' },
  { id: 3, label: 'L', value: 'large' },
  { id: 4, label: 'XL', value: 'xl' },
]

function Filters(props) {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const [products, setProducts] = useState([])
  const [searchProducts, setSearchProducts] = useState([])

  const sizeSelected =async (s) => {
    try {
      const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/products-by-sizes/${s}`,
          {
            headers: {'x-api-key': process.env.REACT_APP_X_API_KEY},
          },
      )
      setSearchProducts(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const colorSelected =async (c) => {
    try {
      const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/products-by-colors/${c}`,
          {
            headers: {'x-api-key': process.env.REACT_APP_X_API_KEY},
          },
      )
      setSearchProducts(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const noColorSelected = async() => {
    try {
      const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/products-by-type/${props.productType}`,
          {
            headers: {'x-api-key': process.env.REACT_APP_X_API_KEY},
          },
      )
      setSearchProducts(response.data);
    } catch (error) {
      console.log( error);
    }
  }

  return (
    <div className="bg-white">
      <div>
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filters
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6 cursor-pointer" aria-hidden="true" />
                    </button>
                  </div>

                  <form className="mt-4 ml-4 border-t border-gray-200">
                    <div
                      as="div"
                      key={1}
                      className="border-b border-gray-200 py-6"
                    >
                      <>
                        <h3 className="-my-3 flow-root">
                          <div className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">
                              Color
                            </span>
                          </div>
                        </h3>
                        <div className="pt-6">
                          <div>
                            <button
                              type="button"
                              className="text-black border border-black focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm p-2 text-center ml-4 mb-2 inline-flex items-center"
                              onClick={noColorSelected}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="w-4 h-4"
                              >
                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                              </svg>
                            </button>
                            <button
                              type="button"
                              className="w-8 h-8 text-white border border-black bg-black hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm -mb-2  text-center inline-flex items-center ml-4"
                              onClick={() => colorSelected('black')}
                            ></button>
                            <button
                              type="button"
                              className="w-8 h-8 text-white border border-[#425757] bg-[#425757] hover:bg-stone-400 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm -mb-2  text-center inline-flex items-center ml-4"
                              onClick={() => colorSelected('blue')}
                            ></button>
                            <button
                              type="button"
                              className="w-8 h-8 text-white border border-[#8c866f] bg-[#8c866f] hover:bg-stone-400 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm -mb-2  text-center inline-flex items-center ml-4"
                              onClick={() => colorSelected('mole')}
                            ></button>
                            <button
                              type="button"
                              className="w-8 h-8 text-white border border-[#86aaa3] bg-[#86aaa3] hover:bg-stone-200 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm -mb-2  text-center inline-flex items-center ml-4"
                              onClick={() => colorSelected('green')}
                            ></button>
                            <button
                              type="button"
                              className="w-8 h-8 text-white border border-[#cdc0b7] bg-[#cdc0b7] hover:bg-stone-200 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm -mb-2  text-center inline-flex items-center ml-4"
                              onClick={() => colorSelected('cream')}
                            ></button>
                            <button
                              type="button"
                              className="w-8 h-8 text-white border border-gray-200 bg-gray-100 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm -mb-2  text-center inline-flex items-center ml-4"
                              onClick={() => colorSelected('white')}
                            ></button>
                          </div>
                        </div>
                      </>
                    </div>
                    <div
                      as="div"
                      key={2}
                      className="border-b border-gray-200 py-6"
                    >
                      <>
                        <h3 className="-my-3 flow-root">
                          <div className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">
                              Size
                            </span>
                          </div>
                        </h3>
                        <div className="pt-6">
                          <div>
                            {sizes.map((option) => (
                              <button
                                key={option.id}
                                type="button"
                                onClick={() => sizeSelected(option.value)}
                                className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-2 focus:ring-gray-400 font-medium text-sm px-4 py-2 mr-3 mb-2"
                              >
                                {option.label}
                              </button>
                            ))}
                          </div>
                        </div>
                      </>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <main className="mx-auto max-w-8xl lg:py-4 md:py-4 sm:py-2 py-2 lg:px-16 md:px-8 sm:px-4 px-4">
          <div className="flex items-baseline justify-between border-b border-gray-200 pt-8 pb-6">
            <div className="flex items-center">
              <button
                type="button"
                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="sr-only">Filters</span>
                <FunnelIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="pt-6 pb-24">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              <form className="hidden lg:block">
                <div as="div" key={1} className="border-b border-gray-200 py-6">
                  <>
                    <h3 className="-my-3 flow-root">
                      <div className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                        <span className="font-medium text-gray-900">Color</span>
                      </div>
                    </h3>
                    <div className="pt-6">
                      <div>
                        <button
                          type="button"
                          className="text-black border border-black focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm p-2 text-center ml-4 mb-2 inline-flex items-center"
                          onClick={noColorSelected}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="w-4 h-4"
                          >
                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                          </svg>
                        </button>
                        <button
                          type="button"
                          className="w-8 h-8 text-white border border-black bg-black hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm -mb-2  text-center inline-flex items-center ml-4"
                          onClick={() => colorSelected('black')}
                        ></button>
                        <button
                          type="button"
                          className="w-8 h-8 text-white border border-[#425757] bg-[#425757] hover:bg-[#59646d] focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm -mb-2  text-center inline-flex items-center ml-4"
                          onClick={() => colorSelected('blue')}
                        ></button>
                        <button
                          type="button"
                          className="w-8 h-8 text-white border border-[#8c866f] bg-[#8c866f] hover:bg-[#948e75] focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm -mb-2  text-center inline-flex items-center ml-4"
                          onClick={() => colorSelected('mole')}
                        ></button>

                        <button
                          type="button"
                          className="w-8 h-8 text-white border border-[#86aaa3] bg-[#86aaa3] hover:bg-[#9fbebb] focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm -mb-2  text-center inline-flex items-center ml-4"
                          onClick={() => colorSelected('green')}
                        ></button>
                        <button
                          type="button"
                          className="w-8 h-8 text-white border border-[#cdc0b7] bg-[#cdc0b7] hover:bg-[#d3c6bd] focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm -mb-2  text-center inline-flex items-center ml-4"
                          onClick={() => colorSelected('cream')}
                        ></button>
                        <button
                          type="button"
                          className="w-8 h-8 text-white border border-gray-200 bg-gray-100 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm -mb-2  text-center inline-flex items-center ml-4"
                          onClick={() => colorSelected('white')}
                        ></button>
                      </div>
                    </div>
                  </>
                </div>
                <div as="div" key={2} className="border-b border-gray-200 py-6">
                  <>
                    <h3 className="-my-3 flow-root">
                      <div className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                        <span className="font-medium text-gray-900">Size</span>
                      </div>
                    </h3>
                    <div className="pt-6">
                      <div>
                        {sizes.map((option) => (
                          <button
                            key={option.id}
                            type="button"
                            onClick={() => sizeSelected(option.value)}
                            className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-2 focus:ring-gray-400 font-medium text-sm px-4 py-2 mr-3 mb-2"
                          >
                            {option.label}
                          </button>
                        ))}
                      </div>
                    </div>
                  </>
                </div>
              </form>

              <div className="lg:col-span-3">
                <Searchbar
                  products={products}
                  setSearchProducts={setSearchProducts}
                />
                <Products
                  productType={props.productType}
                  products={products}
                  setProducts={setProducts}
                  searchProducts={searchProducts}
                  setSearchProducts={setSearchProducts}
                />
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  )
}

export default Filters
