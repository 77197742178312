import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'

import transparentImage from '../../../../assets/home.jpg'
import Header from '../../../../components/Shared/Header'
import Footer from '../../../../components/Shared/Footer'
import {
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import Alert from '../../../../components/Admin/Content/Alert'

function ForgotPassword() {
  const navigate = useNavigate()

  const [username, setUserName] = useState('')
  const [code, setCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)

  const [sendCode, setSendCode] = useState(false)
  const [verifyCode, setVerifyCode] = useState(false)

  const sendEmail = async (event) => {
    event.preventDefault()
    setSuccessMessage(null) // Reset success message
    setErrorMessage(null) // Reset error message

    try {
      Auth.forgotPassword(username)
        .then((data) => {
          setSendCode(true)
          setSuccessMessage('Please check your email for the code.')
        })
        .catch((err) => {
          setErrorMessage(`An error occurred. ${err.message}`)
        })
    } catch (error) {}
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setSuccessMessage(null) // Reset success message
    setErrorMessage(null) // Reset error message

    try {
      Auth.forgotPasswordSubmit(username, code, newPassword)
        .then((data) => {
          setVerifyCode(true)
          setSuccessMessage('Password Reset Successfull!')
          setTimeout(() => {
            navigate('/login')
          }, 3000)
        })
        .catch((err) => {
          if (newPassword !== confirmPassword) {
            setErrorMessage('Passwords do not match. Please try again.')
          } else {
            setErrorMessage(`An error occurred. ${err.message}`)
          }
        })
    } catch (error) {
      setErrorMessage('Error in forgot password. Please try again.')
    }
  }

  const handleCancel = () => {
    setUserName('')
    setCode('')
    setNewPassword('')
    setSendCode(false)
    setVerifyCode(false)

    if (!sendCode) {
      navigate('/login')
    }
  }

  return (
    <div>
      {successMessage && (
        <Alert
          icon={CheckCircleIcon}
          bgColor="relative border-2 border-green-600 m-2 rounded-md bg-green-50 p-4"
          iconColor="h-7 w-7 text-green-400"
          text={successMessage}
          buttonColor="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
          icon2={XMarkIcon}
        />
      )}
      {errorMessage && (
        <Alert
          icon={XCircleIcon}
          bgColor="relative border-2 border-red-600 m-2 rounded-md bg-red-50 p-4"
          iconColor="h-7 w-7 text-red-400"
          text={errorMessage}
          buttonColor="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
          icon2={XMarkIcon}
        />
      )}
      <Header page={5} />
      <div
        className="flex min-h-screen items-center justify-center px-6  lg:px-8"
        style={{
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${transparentImage})`,
          backgroundSize: 'cover',
        }}
      >
        <div className="sm:w-full sm:max-w-sm  rounded-lg py-4 border-2 border-blue-900 px-4  ">
          <h2 className="text-2xl font-bold mb-4 text-blue-700 flex justify-center">
            Reset Password
          </h2>

          {!sendCode ? (
            <div>
              <form onSubmit={sendEmail} className="flex flex-col">
                <div className="flex mb-4">
                  <label className="mr-2">
                    Username*
                    <input
                      className="ml-2 p-2 border border-gray-300 rounded"
                      type="text"
                      name="username"
                      value={username}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </label>
                </div>
                <button
                  className="py-2 px-4 bg-blue-500 text-white rounded"
                  type="submit"
                >
                  Send code
                </button>
                <button
                  className="py-2 px-4 bg-gray-500 text-white rounded mt-2"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </form>
            </div>
          ) : !verifyCode ? (
            <div>
              <form onSubmit={handleSubmit} className="flex flex-col">
                <div className="flex flex-col mb-4">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Code*
                  </label>
                  <input
                    className="ml-2 p-2 border border-gray-300 rounded"
                    type="text"
                    name="code"
                    required
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />

                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Password*
                  </label>
                  <input
                    className="ml-2 p-2 border border-gray-300 rounded"
                    type="password"
                    name="password"
                    required
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />

                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Confirm Password*
                  </label>
                  <input
                    className="ml-2 p-2 border border-gray-300 rounded"
                    type="password"
                    name="confirmNewPassword"
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <button
                  className="py-2 px-4 bg-blue-500 text-white rounded"
                  type="submit"
                >
                  Reset password
                </button>
                <button
                  className="py-2 px-4 bg-gray-500 text-white rounded mt-2"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </form>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ForgotPassword
