import React from 'react'
import { Link } from 'react-router-dom'

function BreadCrumb(props) {
  return (
    <div>
      <nav className="flex py-3 text-gray-700 " aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          <li className="inline-flex items-center">
            <Link to="/">
              <p className="text-sm font-medium text-gray-700 hover:text-gray-900">
                Home
              </p>
            </Link>
          </li>
          {props.path.map((path) => (
            <li key={path.name}>
              <div className="flex items-center">
                <svg
                  className="w-6 h-6 text-gray-700"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <Link to={path.url}>
                  <p className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2">
                    {path.name}
                  </p>
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  )
}

export default BreadCrumb
