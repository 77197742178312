import React from 'react'
import Dashboard from '../../components/Admin/Dashboard/Dashboard'

function ViewAdminSettings() {
  return (
    <div>
      <Dashboard tab={8} title="Settings" />
    </div>
  )
}

export default ViewAdminSettings
