import React from 'react'
import BreadCrumb from './BreadCrumb'

function Heading(props) {
  return (
    <div
      className="bg-cover flex justify-center"
      style={{
        backgroundImage: `url(https://adi-images.s3.us-east-2.amazonaws.com/images/img.jpg)`,
      }}
    >
      <h2 className="lg:text-5xl md:text-3xl sm:text-2xl text-2xl lg:py-64 md:py-32 sm:py-24 py-24  text-shadow-2xl">
        {props.heading}
        <BreadCrumb path={props.breadcrumb} />
      </h2>
    </div>
  )
}

export default Heading
