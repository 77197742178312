import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = {
  cartItems: [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0.0,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart(state, action) {
      const itemIndex = state.cartItems.findIndex((item) => {
        return (
          item.PK === action.payload.PK &&
          item.colorRef.includes(action.payload.colorRef[0]) &&
          item.remarks === action.payload.remarks &&
          item.customization === action.payload.customization
        );
      });
      if (itemIndex >= 0) {
        state.cartItems[itemIndex].qty += action.payload.qty;
        state.cartTotalAmount += action.payload.price * action.payload.qty;
        toast.info('Successfully increased the quantity', {
          position: 'top-right',
        });
      } else {
        const temp = {
          ...action.payload,
          cartQuantity: action.payload.qty,
        };
        state.cartItems.push(temp);
        state.cartTotalQuantity += 1;
        toast.success('Successfully added to the quote', {
          position: 'top-right',
        });
        state.cartTotalAmount += action.payload.price * action.payload.qty;
      }
    },
    removeFromCart(state, action) {
      const newCartItems = state.cartItems.filter((item) => {
        return (
          item.PK !== action.payload.PK ||
          !item.colorRef.includes(action.payload.colorRef[0]) ||
          item.remarks !== action.payload.remarks ||
          item.customization !== action.payload.customization
        );
      });
      toast.success('Successfully removed from the quote', {
        position: 'top-right',
      });
      state.cartItems = newCartItems;
      state.cartTotalQuantity -= 1;

      state.cartTotalAmount -= action.payload.price * action.payload.qty;
    },
    clearCart(state) {
      state.cartItems = [];
      state.cartTotalAmount = 0.0;
      state.cartTotalQuantity = 0;
    },
  },
});

export const { addToCart, removeFromCart, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
