import React from 'react'

function Modal(props) {
  return (
    <div className="fixed inset-0 bg-gray-300 bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-50">
      <section className="max-w-5xl w-full lg:p-8 md:p-8 sm:p-6 p-4 mx-auto bg-white rounded-lg shadow-md h-full md:h-4/5 lg:h-4/5  overflow-y-auto ">
        {props.content}
      </section>
    </div>
  )
}

export default Modal
