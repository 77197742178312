import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { clearCart } from '../../features/cartSlice';
import * as Yup from 'yup';
import axios from 'axios';
import ButtonLoader from '../Shared/ButtonLoader';

window.Buffer = window.Buffer || require('buffer').Buffer;

window.Buffer = window.Buffer || require('buffer').Buffer;

const QuotationForm = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('Whole seller');
  const [clientType, setClientType] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [clientData, setClientData] = useState(null);

  const schema =
    clientType === 'New Client'
      ? yup.object().shape({
          firstName: Yup.string()
            .required('First Name is required')
            .min(4, 'At least 3 characters required')
            .max(500, "Can't exceed 500 characters"),
          lastName: Yup.string()
            .required('Last Name is required')
            .min(4, 'At least 3 characters required')
            .max(500, "Can't exceed 500 characters"),
          companyName: Yup.string().required('Company Name is required'),
          title: Yup.string().required('Title is required'),
          contactNumber1: Yup.string()
            .required('Contact Number 1 is required')
            .matches(
              '^(?:(?:\\+|00)\\d{1,3}\\s?)?(?:\\(?\\d{2,3}\\)?[-.\\s]?)?\\d{3}[-.\\s]?\\d{4}$',
              'Please enter a valid telephone number'
            ),
          contactNumber2: Yup.string().optional(),
          email: Yup.string()
            .required('Email is required')
            .email('Valid email address is required'),

          website: Yup.string().required('Website is required'),
          businessCardData: Yup.mixed()
            .required('Business Card is required')

            .test('fileType', 'Unsupported file type', (value) => {
              if (value && value.length > 0 && value[0].type) {
                return value[0].type.startsWith('image/');
              }
              return true; // Skip the test if value is undefined, empty, or type is undefined
            }),
        })
      : isSearch
      ? yup.object().shape({
          firstname: Yup.string()
            .required('First Name is required')
            .min(4, 'At least 3 characters required')
            .max(500, "Can't exceed 500 characters"),
          lastname: Yup.string()
            .required('Last Name is required')
            .min(4, 'At least 3 characters required')
            .max(500, "Can't exceed 500 characters"),
          company: Yup.string().required('Company Name is required'),
          Title: Yup.string().required('Title is required'),
          mobile: Yup.string()
            .required('Contact Number 1 is required')
            .matches(
              '^(?:(?:\\+|00)\\d{1,3}\\s?)?(?:\\(?\\d{2,3}\\)?[-.\\s]?)?\\d{3}[-.\\s]?\\d{4}$',
              'Please enter a valid telephone number'
            ),
          emailAddress: Yup.string()
            .required('Email is required')
            .email('Valid email address is required'),
          contactnumber2: Yup.string().optional(),
          Website: Yup.string().required('Website is required'),
          businessCardData: Yup.mixed()
            .required('Business Card is required')

            .test('fileType', 'Unsupported file type', (value) => {
              if (value && value.length > 0 && value[0].type) {
                return value[0].type.startsWith('image/');
              }
              return true; // Skip the test if value is undefined, empty, or type is undefined
            }),
        })
      : yup.object().shape({
          company: Yup.string().required('Company Name is required'),
          mobile: Yup.string()
            .required('Mobile Number is required')
            .matches(
              '^(?:(?:\\+|00)\\d{1,3}\\s?)?(?:\\(?\\d{2,3}\\)?[-.\\s]?)?\\d{3}[-.\\s]?\\d{4}$',
              'Please enter a valid telephone number'
            ),
        });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  let firstname = '';
  let lastname = '';
  let email = '';
  let companyName = '';

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64String = e.target.result;
      setSelectedFile(base64String);
      setValue('businessCardData', base64String);
    };
    reader.readAsDataURL(file);
    setSelectedFile(file ? file.name : '');
  };

  async function onSubmitHandler(data) {
    //check the passing data
    data.buyerCategory = filter;
    data.isAdmin = true;
    data = { ...data, businessCardData: selectedFile };
    firstname = data.firstName;
    lastname = data.lastName;
    email = data.email;
    companyName = data.companyName;
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/customer/save`,
        JSON.stringify(data),
        {
          headers: { 'x-api-key': process.env.REACT_APP_X_API_KEY },
        }
      );
      sendQuotation();
      setIsLoading(false);
      reset();
      props.setChoice(true);
      props.setModalOn(false);
      handleClearCart();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  async function onSaveHandler(data) {
    if (clientData.found) {
      firstname = clientData.data.firstName;
      lastname = clientData.data.lastName;
      email = clientData.data.email;
      companyName = clientData.data.companyName;
      setIsLoading(true);
      try {
        sendQuotation();
        setIsLoading(false);
        reset();
        props.setChoice(true);
        props.setModalOn(false);
        handleClearCart();
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    } else {
      data = {
        firstName: data.firstname,
        lastName: data.lastname,
        companyName: data.company,
        title: data.Title,
        contactNumber1: data.mobile,
        contactNumber2: data.contactnumber2,
        email: data.emailAddress,
        website: data.Website,
        businessCardData: data.businessCardData,
      };
      onSubmitHandler(data);
    }
  }

  async function onSearchHandler(data) {
    console.log(data);
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/customer/check/${encodeURIComponent(
          data.company
        )}/${data.mobile}`,
        {
          headers: { 'x-api-key': process.env.REACT_APP_X_API_KEY },
        }
      );
      setClientData(response.data);
      setIsLoading(false);
      setIsSearch(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  const handleCancelClick = () => {
    props.setChoice(false);
    props.setModalOn(false);
  };
  const dispatch = useDispatch();
  const handleClearCart = () => {
    dispatch(clearCart());
  };

  async function sendQuotation() {
    let jsonArrayObject = [];
    const cart = props.cart;
    for (let i = 0; i < cart.cartItems.length; i++) {
      let pk_value = cart.cartItems[i]['PK'];
      let qty_value = cart.cartItems[i]['qty'];
      let notes_value = cart.cartItems[i]['remarks'];
      let color_value = cart.cartItems[i]['colorRef'];
      let customization = cart.cartItems[i]['customization'];
      let image_url = cart.cartItems[i]['image_url'].images[0];

      jsonArrayObject.push({
        itemNo: pk_value,
        qty: qty_value,
        remarks: notes_value,
        colorRef: color_value,
        customisation: customization,
        priceType: '',
        imageUrl: image_url,
      });
    }

    let cartBody = {
      products: jsonArrayObject,
      customerDetails: {
        email: email,
        name: firstname + ' ' + lastname,
        companyName: companyName,
      },
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/save-quotation`,
        JSON.stringify(cartBody),
        {
          headers: { 'x-api-key': process.env.REACT_APP_X_API_KEY },
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className='fixed inset-0 bg-gray-300 bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-50'>
      <section className='max-w-4xl lg:p-12 md:p-8 sm:p-6 p-6 mx-auto bg-white rounded-md shadow-md  '>
        <h1 className='font-bold lg:text-2xl md:text-lg sm:text-md text-md text-center text-black   uppercase'>
          Enter Your Contact Information
        </h1>

        <div className='flex mt-6'>
          <label
            className='text-black lg:text-lg md:text-md sm:text-sm text-xs '
            for='client_type'
          >
            Select the Client Type :
          </label>
          <div className='relative my-auto ml-6'>
            <div className='mx-auto flex justify-center'>
              <div className='flex items-center'>
                <div className='bg-white  rounded-full w-4 h-4 flex flex-shrink-0 justify-center items-center relative'>
                  <input
                    type='radio'
                    name='radio'
                    onClick={() => setClientType('New Client')}
                    className='checkbox appearance-none focus:outline-none border rounded-full border-gray-400 absolute cursor-pointer w-full h-full checked:border-none'
                  />
                  <div className='check-icon hidden border-4 border-black rounded-full w-full h-full z-1' />
                </div>
                <p className='ml-2 lg:text-lg md:text-md sm:text-sm text-xs leading-4 font-normal text-black'>
                  New Client
                </p>
              </div>
              <div className='flex items-center ml-6'>
                <div className='bg-white  rounded-full w-4 h-4 flex flex-shrink-0 justify-center items-center relative'>
                  <input
                    type='radio'
                    name='radio'
                    onClick={() => setClientType('Existing Client')}
                    className='checkbox appearance-none focus:outline-none border rounded-full border-gray-400 absolute cursor-pointer w-full h-full checked:border-none'
                  />
                  <div className='check-icon hidden border-4 border-black rounded-full w-full h-full z-1' />
                </div>
                <p className='ml-2 lg:text-lg md:text-md sm:text-sm text-xs leading-4 font-normal text-black '>
                  Existing Client
                </p>
              </div>
            </div>
          </div>
        </div>
        {clientType === 'New Client' ? (
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className='flex mt-6'>
              <label
                className='text-black lg:text-lg md:text-md sm:text-sm text-xs '
                for='buyerCategory'
              >
                Select the Buyer Type* :
              </label>
              <div className='relative ml-6'>
                <div
                  className='bg-white flex justify-between border rounded w-auto px-2'
                  onClick={() => setShow(!show)}
                >
                  <p className='px-2 py-2 text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs font-normal'>
                    {filter}
                  </p>
                  <div className='bg-white items-center flex '>
                    <div className='cursor-pointer text-gray-600'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke-width='1.5'
                        stroke='currentColor'
                        class='w-4 h-4 my-2'
                      >
                        <path
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                {show && (
                  <ul className='visible transition duration-300 opacity-100 bg-white shadow rounded mt-2 py-1 w-auto absolute cursor-pointer z-10'>
                    <li
                      className='cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center'
                      onClick={() => {
                        setFilter('Whole seller');
                        setShow(false);
                      }}
                    >
                      Whole seller
                    </li>
                    <li
                      className='cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center'
                      onClick={() => {
                        setFilter('Retailer');
                        setShow(false);
                      }}
                    >
                      Retailer
                    </li>
                    <li
                      className='cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center'
                      onClick={() => {
                        setFilter('Trader');
                        setShow(false);
                      }}
                    >
                      Trader
                    </li>
                    <li
                      className='cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center'
                      onClick={() => {
                        setFilter('Buying office');
                        setShow(false);
                      }}
                    >
                      Buying office
                    </li>
                    <li
                      className='cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center'
                      onClick={() => {
                        setFilter('Hypermarket chain');
                        setShow(false);
                      }}
                    >
                      Hypermarket chain
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <div className='grid grid-cols-2 lg:gap-6 md:gap-6 gap-4 mt-4 sm:grid-cols-2'>
              <div>
                <div>
                  <label
                    className='text-black  lg:text-lg md:text-md sm:text-sm text-xs '
                    for='firstName'
                  >
                    First Name*
                  </label>
                  <input
                    id='firstName'
                    type='text'
                    className='block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md
            focus:border-black focus:outline-none focus:ring'
                    {...register('firstName')}
                  />
                </div>
                <div>
                  <div>
                    <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                      {errors.firstName?.message}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <label
                    className='text-black lg:text-lg md:text-md sm:text-sm text-xs '
                    for='lastName'
                  >
                    Last Name*
                  </label>
                  <input
                    id='lastName'
                    type='text'
                    className='block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md
            focus:border-black  focus:outline-none focus:ring'
                    {...register('lastName')}
                  />
                </div>
                <div>
                  <div>
                    <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                      {errors.lastName?.message}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <label
                    className='text-black lg:text-lg md:text-md sm:text-sm text-xs '
                    for='companyName'
                  >
                    Company Name*
                  </label>
                  <input
                    id='companyName'
                    type='text'
                    className='block w-full px-4 py-2   mt-2
           text-black bg-white border rounded-md
            focus:border-black focus:outline-none focus:ring'
                    {...register('companyName')}
                  />
                </div>
                <div>
                  <div>
                    <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                      {errors.companyName?.message}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <label
                    className='text-black lg:text-lg md:text-md sm:text-sm text-xs'
                    for='title'
                  >
                    Title*
                  </label>
                  <input
                    id='title'
                    type='text'
                    className='block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md
            focus:border-black focus:outline-none focus:ring'
                    {...register('title')}
                  />
                </div>
                <div>
                  <div>
                    <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                      {errors.title?.message}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <label
                    className='text-black lg:text-lg md:text-md sm:text-sm text-xs'
                    for='contactNumber1'
                  >
                    Contact Number 1*
                  </label>
                  <input
                    id='contactNumber1'
                    type='text'
                    className='block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md
             focus:border-black  focus:outline-none focus:ring'
                    {...register('contactNumber1')}
                  />
                </div>
                <div>
                  <div>
                    <p className='text-red-700  italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                      {errors.contactNumber1?.message}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <label
                    className='text-black  lg:text-lg md:text-md sm:text-sm text-xs '
                    for='contactNumber2'
                  >
                    Contact Number 2
                  </label>
                  <input
                    id='contactNumber2'
                    type='text'
                    className='block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md 
            focus:border-black focus:outline-none focus:ring'
                    {...register('contactNumber2')}
                  />
                </div>
              </div>

              <div>
                <div>
                  <label
                    className='text-black lg:text-lg md:text-md sm:text-sm text-xs '
                    for='email'
                  >
                    Email Address*
                  </label>
                  <input
                    id='email'
                    type='email'
                    className='block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md
            focus:border-black  focus:outline-none focus:ring'
                    {...register('email')}
                  />
                </div>
                <div>
                  <div>
                    <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                      {errors.email?.message}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <label
                    className='text-black lg:text-lg md:text-md sm:text-sm text-xs'
                    for='website'
                  >
                    Web Site*
                  </label>
                  <input
                    id='website'
                    type='website'
                    className='block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md
            focus:border-black  focus:outline-none focus:ring'
                    {...register('website')}
                  />
                </div>
                <div>
                  <div>
                    <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                      {errors.website?.message}
                    </p>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div className='flex my-4'>
              <label
                className='text-black lg:text-lg md:text-md sm:text-sm text-xs '
                for='businessCardData'
              >
                Attach your Business Card :
              </label>
              <input
                id='businessCardData'
                type='file'
                onChange={handleFileChange}
                className='block w-1/2 ml-8 text-sm text-gray-400 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-gray-50 file:text-gray-700 hover:file:bg-gray-100'
              />
            </div>
            <div className='my-8'>
              <label className='text-black lg:text-lg md:text-md sm:text-sm text-xs'>
                By submitting this form, you consent to receive transactional
                emails from artdecorint.com
              </label>
            </div>

            <div className='flex justify-center mt-6'>
              <button
                type='submit'
                className={`px-7 py-2 leading-5 text-white transition-colors duration-200 transform bg-black rounded-md
       hover:bg-gray-600`}
              >
                <ButtonLoader isLoading={isLoading}></ButtonLoader>
                Save
              </button>
              <button
                onClick={handleCancelClick}
                className='px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-black rounded-md
       hover:bg-gray-600 focus:outline-none focus:bg-gray-600 ml-2'
              >
                Cancel
              </button>
            </div>
          </form>
        ) : clientType === 'Existing Client' ? (
          <form
            onSubmit={handleSubmit(isSearch ? onSaveHandler : onSearchHandler)}
          >
            <div className='grid grid-cols-2 lg:gap-6 md:gap-6 gap-4 mt-4 sm:grid-cols-2'>
              <div>
                <div>
                  <label
                    className='text-black  lg:text-lg md:text-md sm:text-sm text-xs '
                    for='company'
                  >
                    Company Name*
                  </label>
                  <input
                    id='company'
                    type='text'
                    defaultValue={
                      clientData?.found ? clientData.data.companyName : ''
                    }
                    className='block w-full px-4 py-2  mt-2
     text-black bg-white border rounded-md
      focus:border-black focus:outline-none focus:ring'
                    {...register('company')}
                  />
                </div>
                <div>
                  <p className='text-red-700 mt-1 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                    {errors.company?.message}
                  </p>
                </div>
              </div>

              <div>
                <div>
                  <label
                    className='text-black lg:text-lg md:text-md sm:text-sm text-xs '
                    for='mobile'
                  >
                    Mobile Number*
                  </label>
                  <input
                    id='mobile'
                    type='text'
                    defaultValue={
                      clientData?.found ? clientData.data.contactNumber1 : ''
                    }
                    className='block w-full px-4 py-2  mt-2
     text-black bg-white border rounded-md
      focus:border-black  focus:outline-none focus:ring'
                    {...register('mobile')}
                  />
                </div>
                <div>
                  <p className='text-red-700 mt-1 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                    {errors.mobile?.message}
                  </p>
                </div>
              </div>
            </div>
            {isSearch && (
              <div>
                <div className='flex mt-6 border-t-2 pt-4'>
                  <label
                    className='text-black lg:text-lg md:text-md sm:text-sm text-xs '
                    for='buyerCategory'
                  >
                    Select the Buyer Type* :
                  </label>
                  <div className='relative ml-6'>
                    <div
                      className='bg-white flex justify-between border rounded w-auto px-2'
                      onClick={() => setShow(!show)}
                    >
                      <p className='px-2 py-2 text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs font-normal'>
                        {clientData.found
                          ? clientData.data.buyerCategory
                          : filter}
                      </p>
                      <div className='bg-white items-center flex '>
                        <div className='cursor-pointer text-gray-600'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke-width='1.5'
                            stroke='currentColor'
                            class='w-4 h-4 my-2'
                          >
                            <path
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    {show && (
                      <ul className='visible transition duration-300 opacity-100 bg-white shadow rounded mt-2 py-1 w-auto absolute cursor-pointer z-10'>
                        <li
                          className='cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center'
                          onClick={() => {
                            setFilter('Whole seller');
                            setShow(false);
                          }}
                        >
                          Whole seller
                        </li>
                        <li
                          className='cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center'
                          onClick={() => {
                            setFilter('Retailer');
                            setShow(false);
                          }}
                        >
                          Retailer
                        </li>
                        <li
                          className='cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center'
                          onClick={() => {
                            setFilter('Trader');
                            setShow(false);
                          }}
                        >
                          Trader
                        </li>
                        <li
                          className='cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center'
                          onClick={() => {
                            setFilter('Buying office');
                            setShow(false);
                          }}
                        >
                          Buying office
                        </li>
                        <li
                          className='cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center'
                          onClick={() => {
                            setFilter('Hypermarket chain');
                            setShow(false);
                          }}
                        >
                          Hypermarket chain
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
                <div className='grid grid-cols-2 lg:gap-6 md:gap-6 gap-4 mt-4 sm:grid-cols-2'>
                  <div>
                    <div>
                      <label
                        className='text-black  lg:text-lg md:text-md sm:text-sm text-xs '
                        for='firstname'
                      >
                        First Name*
                      </label>
                      <input
                        id='firstname'
                        type='text'
                        defaultValue={
                          clientData.found ? clientData.data.firstName : ''
                        }
                        className='block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md
            focus:border-black focus:outline-none focus:ring'
                        {...register('firstname')}
                      />
                    </div>
                    <div>
                      <div>
                        <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                          {errors.firstname?.message}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <label
                        className='text-black lg:text-lg md:text-md sm:text-sm text-xs '
                        for='lastname'
                      >
                        Last Name*
                      </label>
                      <input
                        id='lastname'
                        type='text'
                        defaultValue={
                          clientData.found ? clientData.data.lastName : ''
                        }
                        className='block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md
            focus:border-black  focus:outline-none focus:ring'
                        {...register('lastname')}
                      />
                    </div>
                    <div>
                      <div>
                        <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                          {errors.lastname?.message}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <label
                        className='text-black lg:text-lg md:text-md sm:text-sm text-xs'
                        for='Title'
                      >
                        Title*
                      </label>
                      <input
                        id='Title'
                        type='text'
                        defaultValue={
                          clientData.found ? clientData.data.title : ''
                        }
                        className='block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md
            focus:border-black focus:outline-none focus:ring'
                        {...register('Title')}
                      />
                    </div>
                    <div>
                      <div>
                        <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                          {errors.Title?.message}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <label
                        className='text-black  lg:text-lg md:text-md sm:text-sm text-xs '
                        for='contactnumber2'
                      >
                        Contact Number 2
                      </label>
                      <input
                        id='contactnumber2'
                        type='text'
                        defaultValue={
                          clientData.found ? clientData.data.contactNumber2 : ''
                        }
                        className='block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md 
            focus:border-black focus:outline-none focus:ring'
                        {...register('contactnumber2')}
                      />
                    </div>
                  </div>

                  <div>
                    <div>
                      <label
                        className='text-black lg:text-lg md:text-md sm:text-sm text-xs '
                        for='emailAddress'
                      >
                        Email Address*
                      </label>
                      <input
                        id='emailAddress'
                        type='email'
                        defaultValue={
                          clientData.found ? clientData.data.email : ''
                        }
                        className='block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md
            focus:border-black  focus:outline-none focus:ring'
                        {...register('emailAddress')}
                      />
                    </div>
                    <div>
                      <div>
                        <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                          {errors.emailAddress?.message}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <label
                        className='text-black lg:text-lg md:text-md sm:text-sm text-xs'
                        for='website'
                      >
                        Web Site*
                      </label>
                      <input
                        id='Website'
                        type='website'
                        defaultValue={
                          clientData.found ? clientData.data.website : ''
                        }
                        className='block w-full px-4 py-2  mt-2
           text-black bg-white border rounded-md
            focus:border-black  focus:outline-none focus:ring'
                        {...register('Website')}
                      />
                    </div>
                    <div>
                      <div>
                        <p className='text-red-700 italic w-100 text-left lg:text-sm md:text-sm sm:text-xs text-xs'>
                          {errors.Website?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                {clientData.found ? (
                  <div className='flex my-4'>
                    <label
                      className='text-black lg:text-lg md:text-md sm:text-sm text-xs '
                      for='businessCardData'
                    >
                      Business Card :
                    </label>
                    <img
                      src={clientData.data.businessCardUrl}
                      alt='businessCardData'
                      className='rounded-xl ml-2'
                      width={100}
                      height='10'
                    />
                  </div>
                ) : (
                  <div className='flex my-4'>
                    <label
                      className='text-black lg:text-lg md:text-md sm:text-sm text-xs '
                      for='businessCardData'
                    >
                      Attach your Business Card :
                    </label>
                    <input
                      id='businessCardData'
                      type='file'
                      onChange={handleFileChange}
                      className='block w-1/2 ml-8 text-sm text-gray-400 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-gray-50 file:text-gray-700 hover:file:bg-gray-100'
                    />
                  </div>
                )}

                <div className='my-8'>
                  <label
                    className='text-black lg:text-lg md:text-md sm:text-sm text-xs'
                    for='emailAddress'
                  >
                    By submitting this form, you consent to receive
                    transactional emails from artdecorint.com
                  </label>
                </div>
              </div>
            )}

            <div className='flex justify-center mt-6'>
              {isSearch ? (
                clientData?.found ? (
                  <button
                    type='button'
                    onClick={onSaveHandler}
                    className='px-7 py-2 leading-5 text-white transition-colors duration-200 transform bg-black rounded-md
   hover:bg-gray-600 focus:outline-none focus:bg-gray-600'
                  >
                    <ButtonLoader isLoading={isLoading}></ButtonLoader>
                    Confirm
                  </button>
                ) : (
                  <button
                    type='submit'
                    disabled={!isValid || isSubmitting}
                    className={`px-7 py-2 leading-5 text-white transition-colors duration-200 transform bg-black rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 ${
                      !isValid || isSubmitting
                        ? 'opacity-50 cursor-not-allowed'
                        : ''
                    }`}
                  >
                    <ButtonLoader isLoading={isLoading}></ButtonLoader>
                    Save
                  </button>
                )
              ) : (
                <button
                  type='submit'
                  disabled={!isValid || isSubmitting}
                  className={`px-7 py-2 leading-5 text-white transition-colors duration-200 transform bg-black rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 ${
                    !isValid || isSubmitting
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                >
                  <ButtonLoader isLoading={isLoading}></ButtonLoader>
                  Search
                </button>
              )}

              <button
                onClick={handleCancelClick}
                className='px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-black rounded-md
   hover:bg-gray-600 focus:outline-none focus:bg-gray-600 ml-2'
              >
                Cancel
              </button>
            </div>
          </form>
        ) : (
          <div className='flex justify-center mt-6'>
            <button
              onClick={handleCancelClick}
              className='px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-black rounded-md
 hover:bg-gray-600 focus:outline-none focus:bg-gray-600 ml-2'
            >
              Cancel
            </button>
          </div>
        )}
      </section>
    </div>
  );
};

export default QuotationForm;
