import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Color(props) {
  return (
    <div>
      {props.color.map((c) => (
        <>
          <button
            type="button"
            disabled
            className={classNames(
              c.item_color === "white" ||
                c.item_color === "WHITE" ||
                c.item_color === "White"
                ? "border-[#a7a5a2] bg-[#ffffff]"
                : c.item_color === "black" ||
                  c.item_color === "BLACK" ||
                  c.item_color === "Black"
                ? "border-[#181817] bg-[#000000]"
                : c.item_color === "cast" ||
                  c.item_color === "CAST" ||
                  c.item_color === "Cast"
                ? "border-[#47474b] bg-[#57565b]"
                : c.item_color === "blue" ||
                  c.item_color === "BLUE" ||
                  c.item_color === "Blue"
                ? "border-[#425757] bg-[#425757]"
                : c.item_color === "mole" ||
                  c.item_color === "MOLE" ||
                  c.item_color === "Mole"
                ? "border-[#8c866f] bg-[#8c866f]"
                : c.item_color === "green" ||
                  c.item_color === "GREEN" ||
                  c.item_color === "Green"
                ? "border-[#86aaa3] bg-[#86aaa3]"
                : c.item_color === "cream" ||
                  c.item_color === "CREAM" ||
                  c.item_color === "Cream"
                ? "border-[#cdc0b7] bg-[#cdc0b7]"
                : c.item_color === "sand" ||
                  c.item_color === "SAND" ||
                  c.item_color === "Sand"
                ? "border-[#e5b39e] bg-[#e5b39e]"
                : c.item_color === "tope" ||
                  c.item_color === "TOPE" ||
                  c.item_color === "Tope"
                ? "border-[#dcd3d2] bg-[#dcd3d2]"
                : c.item_color === "red" ||
                  c.item_color === "RED" ||
                  c.item_color === "Red"
                ? "border-[#ab1939] bg-[#ab1939]"
                : c.item_color === "multi" ||
                  c.item_color === "MULTI" ||
                  c.item_color === "Multi"
                ? "border-[#74361c] bg-[#74361c]"
                : c.item_color === "silk" ||
                  c.item_color === "SILK" ||
                  c.item_color === "Silk"
                ? "border-[#c19e8a] bg-[#c19e8a]"
                : c.item_color === "dust" ||
                  c.item_color === "DUST" ||
                  c.item_color === "Dust"
                ? "border-[#674c38] bg-[#674c38]"
                : "border-[#7a7484] bg-[#7a7484]",
              "lg:w-6 md:w-6 sm:w-5 w-5 lg:h-6 md:h-6 sm:h-5 h-5 text-white border focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm -mb-2  text-center inline-flex items-center mr-4"
            )}
          ></button>
        </>
      ))}
    </div>
  );
}

export default Color;
