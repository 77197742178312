import { useEffect } from 'react'
import { UsersIcon } from '@heroicons/react/24/outline'

import Analytics from './Analytics'
import { useStatisticsContext } from '../../../context/StatisticsContext'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Summary() {
  const {
    exhibitionCount,
    exhibitionPurchaseCount,
    websitePurchaseCount,
    getExhibitionVisitorCount,
    getExhibitionPurchaseCount,
    getWebsitePurchaseCount,
  } = useStatisticsContext()

  useEffect(() => {
    getExhibitionVisitorCount()
    getExhibitionPurchaseCount()
    getWebsitePurchaseCount()
  }, [])

  return (
    <section className="container px-4 lg:py-8 md:py-4 sm:py-4 py-4 mx-auto">
      <div className="flex flex-col lg:mx-auto">
        <div
          role="list"
          className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8 my-2 mx-2"
        >
          <div
            key=""
            className="overflow-hidden rounded-xl border border-gray-200"
          >
            <div className="flex items-center gap-x-4 p-6">
              <div class="w-full overflow-x-auto">
                <table class="table-auto w-full">
                  <tbody>
                    <tr>
                      <td class=" px-4 py-2 text-left text-[#44567C] font-medium">
                        Count of client who visted the exhibition
                      </td>
                    </tr>
                    <tr>
                      <td className=" px-4 py-2 text-left">
                        <div className="flex items-center">
                          <div className="h-10 w-10 rounded-full bg-[#F1F6FC] p-3">
                            <UsersIcon
                              className="h-6 w-6 fill-[#3F70FD] text-white"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-4 text-[#152C5B] font-bold">
                            {exhibitionCount.Count}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className=" px-4 py-2 text-left" colspan="2">
                        <div>
                          <div className="w-full bg-gray-200 rounded-full">
                            <div
                              className={`h-2 bg-[#23C38E] rounded-full sm:w-${exhibitionCount.Count} md:w-${exhibitionCount.Count} lg:w-${exhibitionCount.Count}`}
                            ></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            key=""
            className="overflow-hidden rounded-xl border border-gray-200"
          >
            <div className="flex items-center gap-x-4 p-6">
              <div class="w-full overflow-x-auto">
                <table class="table-auto w-full">
                  <tbody>
                    <tr>
                      <td class=" px-4 py-2 text-left text-[#44567C] font-medium">
                        Count of clients who purchased items
                      </td>
                    </tr>
                    <tr>
                      <td className=" px-4 py-2 text-left">
                        <div className="text-left text-sm text-[#8995AD]">
                          From Exhibition
                        </div>
                        <div className="flex items-center">
                          <div className=" h-10 w-10 rounded-full bg-[#ECFBF7]  p-3">
                            <UsersIcon
                              className="h-6 w-6 fill-[#23C38E] text-white"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-4 text-[#152C5B] font-bold">
                            {exhibitionPurchaseCount.Count}
                          </div>
                        </div>
                      </td>
                      <td className=" px-4 py-2 text-right text-[#8995AD]">
                        <div className="text-left text-sm text-[#8995AD]">
                          From Website
                        </div>

                        <div className="flex items-center">
                          <div className=" h-10 w-10 rounded-full bg-[#F7F1E7]  p-3">
                            <UsersIcon
                              className="h-6 w-6 fill-[#F2C578] text-white"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-4 text-[#152C5B] font-bold">
                            {websitePurchaseCount.Count}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Analytics />
    </section>
  )
}
