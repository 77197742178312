import React, { useState } from 'react';
import {
  BrowserRouter,
  Routes as Switch,
  Route,
  Navigate
} from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

import { useAuth } from './context';
import PageNotFound from './components/Shared/PageNotFound';

import ViewAdminLogin from './Views/AdminFeatures/Auth/ViewAdminLogin';

import ViewContactUs from './Views/ViewContactUs';
import ViewHome from './Views/ViewHome';
import ViewNewArrivals from './Views/ViewNewArrivals';
import ViewOrderSummary from './Views/ViewOrderSummary';
import ViewProductCatalogue from './Views/ViewProductCatalogue';
import ViewProductDetails from './Views/ViewProductDetails';
import ViewRegistration from './Views/ViewRegistration';
import Loading from './Views/AdminFeatures/Auth/Loading';
import AdminRoutes from './Views/AdminFeatures/Auth/AdminRoutes';
import ForgotPassword from './Views/AdminFeatures/Auth/Settings/ForgotPassword';
import ViewProductQRScan from './components/ProductDetails/ViewProductQRScan';

function Routes() {
  const { isAuthReady, isAuthenticated, signIn, signOut } = useAuth();

  const auth = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        signIn(user);
      }
    } catch (e) {
      await Auth.signOut();
      signOut();
    }
  }, [signIn, signOut]);

  useEffect(() => {
    auth();
  }, [auth]);
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' element={<ViewHome />} />
        <Route path='/contactus' element={<ViewContactUs />} />
        <Route path='/products' element={<ViewProductCatalogue />} />
        <Route path='/scan-qr' element={<ViewProductQRScan />} />
        <Route path='/arrivals' element={<ViewNewArrivals />} />
        <Route path='/quote' element={<ViewOrderSummary />} />
        <Route path='/products/:prodid' element={<ViewProductDetails />} />
        <Route path='/login' element={<ViewAdminLogin />} />
        <Route path='/registration' element={<ViewRegistration />} />
        <Route
          path='/admin/*'
          element={
            !isAuthReady ? (
              <Loading />
            ) : isAuthenticated ? (
              <AdminRoutes />
            ) : (
              <Navigate to='/login' />
            )
          }
        />
        <Route path='/forgot-password' element={<ForgotPassword />} />

        <Route path='*' element={<PageNotFound />} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
