import React from 'react';

function QuotationDetails(props) {
  return (
    <section class='container lg:py-8 md:py-4 sm:py-4 py-4 mx-auto'>
      <div class='flex flex-col mt-6 lg:mx-auto'>
        <div class=' -my-2 overflow-x-auto xl:block lg:block md:block hidden'>
          <div class='inline-block min-w-full py-2 align-middle'>
            <div class='overflow-hidden border border-gray-200  md:rounded-lg'>
              <table class='min-w-full divide-y divide-gray-200 '>
                <thead class='bg-gray-50 '>
                  <tr>
                    {props.columns.map((c, i) => (
                      <th
                        key={i}
                        scope='col'
                        class='px-2 py-3.5 lg:text-sm md:text-sm sm:text-xs text-xs font-normal text-left rtl:text-right text-gray-500 '
                      >
                        {c.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody class='bg-white divide-y divide-gray-200 '>
                  {props.data.map((d, i) => (
                    <tr key={i}>
                      {props.columns.map((c) => (
                        <td className='px-4 py-4 lg:text-sm md:text-sm sm:text-xs text-xs text-gray-500 whitespace-nowrap'>
                          {c.selector === 'imageUrl' ? (
                            <img
                              src={d[c.selector]}
                              alt='product_img'
                              className='rounded-xl h-8'
                            />
                          ) : d[c.selector]?.length > 15 ? (
                            `${d[c.selector].slice(0, 15)}...`
                          ) : (
                            d[c.selector]
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 gap-4 xl:hidden lg:hidden md:hidden'>
          {props.data.map((d, i) => (
            <div key={i} className='bg-white p-4 rounded-lg shadow divide-y '>
              {props.columns.map((c) => (
                <div className='py-3'>
                  <p className='text-xs text-gray-400'>{c.name}</p>
                  <p className='text-sm leading-none text-justify text-gray-800 mt-2'>
                    {d[c.selector]}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default QuotationDetails;
