import { XMarkIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import Icon from '../../Shared/Icon'

export default function Alert(props) {
  const [showAlert, setShowAlert] = useState(false)

  const handleDismiss = () => {
    setShowAlert(true)
  }

  return (
    !showAlert && (
      <div className={props.bgColor}>
        <div className="flex">
          <div className="flex-shrink-0">
            <Icon
              icon={props.icon}
              className={props.iconColor}
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className={props.textColor}>{props.text}</p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className={props.buttonColor}
                onClick={handleDismiss}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5 cursor-pointer" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
