import axios from 'axios'
import React, { createContext, useContext, useState } from 'react'
import { AuthContext } from './AuthProvider'

export const StatisticsContext = createContext()

export const StatisticsProvider = ({ children }) => {
  const [exhibitionCount, setExhibitionCount] = useState([])
  const [exhibitionPurchaseCount, setExhibitionPurchaseCount] = useState([])
  const [websitePurchaseCount, setWebsitePurchaseCount] = useState([])
  const [data, setData] = useState([])
  const { token } = useContext(AuthContext)
  const getWebSiteVisitorCountByMonths = async (filter) => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/website-visitor-count/${filter}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((response) => {
          setData(response.data)
        })
    } catch (error) {}
  }
  const getExhibitionVisitorCount = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/exhibition-visitor-count`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setExhibitionCount(response.data)
        })
    } catch (error) {}
  }
  const getExhibitionPurchaseCount = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/exhibition-purchase-count`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setExhibitionPurchaseCount(response.data)
        })
    } catch (error) {}
  }
  const getWebsitePurchaseCount = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/website-purchase-count`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setWebsitePurchaseCount(response.data)
        })
    } catch (error) {}
  }
  return (
    <StatisticsContext.Provider
      value={{
        data,
        exhibitionCount,
        exhibitionPurchaseCount,
        websitePurchaseCount,
        getExhibitionVisitorCount,
        getExhibitionPurchaseCount,
        getWebsitePurchaseCount,
        getWebSiteVisitorCountByMonths,
      }}
    >
      {children}
    </StatisticsContext.Provider>
  )
}

export const useStatisticsContext = () => useContext(StatisticsContext)
