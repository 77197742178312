import React, { useState } from "react";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function DashboardTitle(props) {
  // const [modalOpen, setModalOpen] = useState(false);
  // const viewSlide = () => {
  //   setModalOpen(true);
  // };
 
  function handleClick() {
    alert(props.text);
  }
  ////
  // const [modalIsOpen, setIsOpen] = React.useState(false);

  // function openFromParent() {
  //   setIsOpen(true);
  // }

  

  return (
    <div className="w-full mx-auto">
      <h1 className="lg:text-3xl md:text-3xl sm:text-2xl text-2xl font-semibold text-gray-600 lg:mb-0 md:mb-0 sm:mb-4 mb-4">
        {props.title}
      </h1>

      <div
        className={classNames(
          (props.selectedTab === 7) | (props.selectedTab === 8)
            ? "hidden"
            : "relative",
          "flex justify-end md:mt-2"
        )}
      >
        <div className="relative inset-y-0 left-8 flex items-center pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          type="text"
          id="search"
          className="lg:w-1/4 md:w-1/2 sm:w-full w-full lg:h-12 md:h-12 sm:h-10 h-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-1 focus:outline-none focus:ring-blue-500 focus:border-blue-500 block pl-10 p-2.5 my-auto"
          placeholder={props.search}
          onChange={props.handleSearch}
        />

        <button
          type="button"
          onClick={props.viewSlide}
          className={classNames(
            props.selectedTab === 2 ||
              props.selectedTab === 5 ||
              props.selectedTab === 6 ||
              props.selectedTab === 8
              ? "hidden"
              : "relative",
            "ml-4 py-2 px-2 lg:w-1/6 md:w-1/3 sm:w-full w-full bg-blue-800 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center lg:text-base md:text-base sm:text-sm text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
          )}
        >
          {props.button}
        </button>
      </div>
    </div>
  );
}

export default DashboardTitle;
