import React, { useState, useEffect } from 'react'
import Dashboard from '../../components/Admin/Dashboard/Dashboard'
import { useFeedbacksContext } from '../../context/FeedbackContext'

function ViewAdminFeedbacks() {
  const [tableData,setTableData]= useState([])
  const { feedbacks, getAllFeedbacks } = useFeedbacksContext()

  useEffect(() => {
    getAllFeedbacks()
    setTableData(feedbacks)
  }, [])

  useEffect(() => {
    setTableData(feedbacks)
  }, [feedbacks]);

  function handleSearch(e) {
    if (e.target.value === "") {
      getAllFeedbacks()
    }

    const filteredData = feedbacks.filter((row) => {
      return row["subject"]
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setTableData(filteredData)
  }


  const columns = [
    { name: 'VISITOR NAME', selector: 'name' },
    { name: 'PHONE NUMBER', selector: 'phone' },
    { name: 'EMAIL ADDRESS', selector: 'email' },
    { name: 'SUBJECT', selector: 'subject' },
    { name: 'MESSAGE', selector: 'message' },
    { name: 'ACTIONS', selector: 'actions' },
  ]

  return (
    <div>
      <Dashboard
        tab={5}
        title="Feedbacks"
        search="Search feedbacks..."
        columns={columns}
        data={tableData}
        handleSearch={handleSearch}
      />
    </div>
  )
}

export default ViewAdminFeedbacks
