import React from 'react'

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from 'recharts'
import { useStatisticsContext } from '../../../context/StatisticsContext'

function Graph() {
  const { data } = useStatisticsContext()

  return (
    <div className="flex justify-center">
      <BarChart width={1000} height={500} data={data}>
        <XAxis dataKey="month" stroke="#3F70FD" />
        <YAxis />
        <Tooltip wrapperStyle={{ width: 200, backgroundColor: '#ccc' }} />
        <Legend
          width={200}
          wrapperStyle={{
            top: 10,
            right: 20,
            backgroundColor: '#f5f5f5',
            border: '1px solid #d5d5d5',
            borderRadius: 3,
            lineHeight: '40px',
          }}
        />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <Bar dataKey="count" fill="#3F70FD" barSize={15} />
      </BarChart>
    </div>
  )
}

export default Graph
