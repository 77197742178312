import React, { createContext, useState, useContext } from 'react'
import {
  XMarkIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import axios from 'axios'
import Actions from '../components/Admin/Content/Actions'
import { AuthContext } from './AuthProvider'
import Alert from '../components/Admin/Content/Alert'

export const ClientsContext = createContext()

export const ClientsProvider = ({ children }) => {
  const [clients, setClients] = useState([])
  const [showError, setShowError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [successMsg, setSuccessMsg] = useState(null)
  const { token } = useContext(AuthContext) // Accessing the token from the AuthContext

  const createClient = async (newClient) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/customer/save`,
        newClient,
        {
          headers: { 'x-api-key': process.env.REACT_APP_X_API_KEY },
        },
      )
      setSuccessMsg('Client Created Successfully')
      setShowSuccess(true)
      setTimeout(() => {
        setShowSuccess(false)
        setSuccessMsg(null)
      }, 3000)
      getAllClients()
    } catch (error) {
      setErrorMsg('Failed to Create the Client Account')
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
        setErrorMsg(null)
      }, 3000)
      console.error(error)
    }
  }
  const deleteClients = async (clientId) => {
    try {
      const client = await getClientByID(clientId)
      const { businessCardUrl, SK } = client[0]

      const body = {
        businessCardUrl: businessCardUrl,
        SK: SK,
      }

      await axios.delete(
        `${process.env.REACT_APP_API_URL}/customer/delete-customer/${clientId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: JSON.stringify(body),
        },
      )
      setSuccessMsg('Client Account Deleted Successfully')
      setShowSuccess(true)
      setTimeout(() => {
        setShowSuccess(false)
        setSuccessMsg(null)
      }, 3000)
      getAllClients()
    } catch (error) {
      setErrorMsg('Failed to Delete the Client Account')
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
        setErrorMsg(null)
      }, 3000)
      console.error(error)
    }
  }

  const getAllClients = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/all-customers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response.data
        const cli = []
        for (let i = 0; i < data.length; i++) {
          const temp_Client = {
            PK: data[i].PK,
            SK: data[i].SK,
            firstName: data[i].firstName,
            lastName: data[i].lastName,
            companyName: data[i].companyName,
            contactNumber1: data[i].contactNumber1,
            email: data[i].email,
            actions: (
              <Actions
                selectedTab={3}
                clientId={data[i].PK}
                name={data[i].SK}
              />
            ),
          }
          cli.push(temp_Client)
        }
        setClients(cli)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getClientByID = async (clientId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/customer/${clientId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      return response.data
    } catch (error) {}
  }

  const updateClient = async (clientId, updatedData) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/customer/update-customer/${clientId}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      const updatedClient = await getClientByID(clientId)

      setClients((prevClients) => {
        const updatedClients = prevClients.map((client) => {
          if (client.PK === updatedClient.PK) {
            return {
              ...client,
              SK: updatedClient.SK,
              businessCardUrl: updatedClient.businessCardUrl,
              updatedBusinessCard: updatedClient.updatedBusinessCard,
              buyerCategory: updatedClient.buyerCategory,
              companyName: updatedClient.companyName,
              contactNumber1: updatedClient.contactNumber1,
              contactNumber2: updatedClient.contactNumber2,
              email: updatedClient.email,
              firstName: updatedClient.firstName,
              lastName: updatedClient.lastName,
              title: updatedClient.title,
              website: updatedClient.website,
            }
          }
          return client
        })

        return updatedClients
      })
      setSuccessMsg('Client Account Details Updated Successfully')
      setShowSuccess(true)
      setTimeout(() => {
        setShowSuccess(false)
        setSuccessMsg(null)
      }, 3000)
      getAllClients()
    } catch (error) {
      setErrorMsg('Failed to Update the Client Account Details')
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
        setErrorMsg(null)
      }, 3000)
    }
  }
  return (
    <ClientsContext.Provider
      value={{
        clients,
        createClient,
        getAllClients,
        getClientByID,
        updateClient,
        deleteClients,
      }}
    >
      {showSuccess && (
        <Alert
          icon={CheckCircleIcon}
          bgColor="fixed right-0 top-0 border-2 border-green-600 m-2 rounded-md bg-green-50 p-4"
          iconColor="h-7 w-7 text-green-400"
          text={successMsg}
          buttonColor="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
          icon2={XMarkIcon}
        />
      )}
      {showError && (
        <Alert
          icon={XCircleIcon}
          bgColor="fixed right-0 top-0 border-2 border-red-600 m-2 rounded-md bg-red-50 p-4"
          iconColor="h-7 w-7 text-red-400"
          text={errorMsg}
          buttonColor="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
          icon2={XMarkIcon}
        />
      )}
      {children}
    </ClientsContext.Provider>
  )
}

export const useClientsContext = () => useContext(ClientsContext)
