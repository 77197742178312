import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart } from '../../features/cartSlice';
import BreadCrumb from '../Shared/BreadCrumb';
import EmptyCart from './EmptyCart';
import QuotationForm from './QuotationForm';
import { AnalyticsAPI } from '../../apis/analyticsAPI';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
// export cart = useSelector((state) => state.cart);
function OrderSummary() {
  const [modalOn, setModalOn] = useState(false);
  const [choiceOn, setChoiceOn] = useState(false);
  const [errorOn, setErrorOn] = useState(false);

  const cart = useSelector((state) => state.cart);

  const dispatch = useDispatch();
  const total = useSelector((state) => state.cart.cartTotalAmount);

  const handleRemoveFromCart = (removedProduct) => {
    callAnalyticAPI(
      'User is on Quote page and removed a product',
      JSON.stringify(removedProduct)
    );
    dispatch(removeFromCart(removedProduct));
  };

  const clicked = () => {
    localStorage.setItem('cart', JSON.stringify(cart));
    setModalOn(true);
  };

  function callAnalyticAPI(status, products) {
    const body = {
      state: status,
      productDetails: products,
      hasPurchasedItems: false,
      hasVisited: window.innerWidth <= 768 ? 'exhibition' : 'website',
      recordedDateTime: new Date().toISOString(),
    };
    AnalyticsAPI.getUserState(body)
      .then((response) => {})
      .catch((error) => {});
  }

  useEffect(() => {
    callAnalyticAPI(
      'User is on Quote page with products',
      JSON.stringify(cart)
    );
  }, []);

  return (
    <div className='2xl:container 2xl:mx-auto py-14 px-4 md:px-6 xl:px-20'>
      {choiceOn && (
        <div
          className='flex lg:w-1/3 md:w-2/3 w-full lg:absolute md:absolute lg:right-0 md:right-0 p-4 mb-4 bg-green-100 rounded-lg mt-20'
          role='alert'
        >
          <svg
            aria-hidden='true'
            className='flex-shrink-0 w-5 h-5 text-green-700'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill-rule='evenodd'
              d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z'
              clip-rule='evenodd'
            ></path>
          </svg>
          <div className='ml-3 text-sm font-medium text-green-700'>
            Thank You! Your order placed successfully!!
          </div>
          <button
            type='button'
            onClick={() => {
              setChoiceOn(false);
            }}
            className='ml-auto -mx-1.5 -my-1.5 bg-green-100 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8'
            data-dismiss-target='#alert-3'
            aria-label='Close'
          >
            <svg
              aria-hidden='true'
              className='w-5 h-5'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clip-rule='evenodd'
              ></path>
            </svg>
          </button>
        </div>
      )}
      {errorOn && (
        <div
          id='alert-2'
          className='flex p-4 mb-4 text-red-800 rounded-lg bg-red-50 mt-20'
          role='alert'
        >
          <svg
            aria-hidden='true'
            className='flex-shrink-0 w-5 h-5'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill-rule='evenodd'
              d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z'
              clip-rule='evenodd'
            ></path>
          </svg>
          <span className='sr-only'>Info</span>
          <div className='ml-3 text-sm font-medium'>
            We are Sorry! We were unable to place your order!
          </div>
          <button
            type='button'
            onClick={() => {
              setErrorOn(false);
            }}
            className='ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8'
            data-dismiss-target='#alert-2'
            aria-label='Close'
          >
            <span className='sr-only'>Close</span>
            <svg
              className='w-5 h-5'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clip-rule='evenodd'
              ></path>
            </svg>
          </button>
        </div>
      )}
      <BreadCrumb path={[{ name: 'Quote', url: '/quote' }]} />

      {cart.cartItems.length === 0 ? (
        <EmptyCart />
      ) : (
        <div className='flex flex-col xl:flex-row justify-center items-center space-y-10 xl:space-y-0 xl:space-x-8'>
          <div className='w-full lg:w-9/12 xl:w-full'>
            <img
              className='w-full hidden xl:block'
              src='https://adi-images.s3.us-east-2.amazonaws.com/images/quoteImg1.jpeg'
              alt='Quote Img'
            />
            <img
              className='w-full hidden md:block xl:hidden'
              src='https://adi-images.s3.us-east-2.amazonaws.com/images/quoteImg2.jpeg'
              alt='Quote Img'
            />
            <img
              className='w-full md:hidden'
              src='https://adi-images.s3.us-east-2.amazonaws.com/images/quoteImg2.jpeg'
              alt='Quote Img'
            />
          </div>
          <div className='flex justify-center flex-col items-start w-full lg:w-9/12 xl:w-full '>
            <h3 className='text-3xl xl:text-4xl font-semibold leading-7 xl:leading-9 w-full  md:text-left text-gray-800'>
              Generate Quote
            </h3>
            <p className='text-base leading-none mt-4 text-gray-800'>
              Art decor always the right choice
            </p>
            <div className='flex justify-center items-center w-full mt-8  flex-col space-y-4 '>
              {cart.cartItems.map((p) => (
                <div
                  key={p.PK}
                  className='flex md:flex-row justify-start items-start md:items-center  border border-gray-200 w-full'
                >
                  <div className='w-40 md:w-32 my-auto'>
                    <img src={p.image_url.images[0]} alt={p.SK} />
                  </div>
                  <div className='flex justify-start md:justify-between items-start md:items-center  flex-col md:flex-row w-full p-4 md:px-8'>
                    <div className='flex flex-col md:flex-shrink-0  justify-start items-start'>
                      <div className='flex'>
                        <h3 className='text-lg md:text-xl  w-full font-semibold leading-6 md:leading-5  text-gray-800'>
                          {p.SK} -{' '}
                          {p.colorRef &&
                          p.colorRef.length &&
                          p.colorRef[0].color_finish
                            ? p.colorRef[0].color_finish
                            : p.colorRef && p.colorRef.length
                            ? p.colorRef[0]
                            : ''}
                        </h3>
                      </div>
                      {p.colorRef[0] === 'customized' && (
                        <div className='flex justify-start mt-2'>
                          <h5 className='text-sm md:text-sm font-semibold text-gray-800'>
                            Customization :
                            <span className='text-sm font-normal text-gray-800 ml-2'>
                              {p.customization}
                            </span>
                          </h5>
                        </div>
                      )}

                      <div className='flex flex-row justify-start  space-x-4 md:space-x-6 items-start mt-4'>
                        <p className='text-sm leading-none text-gray-800'>
                          {p.PK}
                        </p>
                      </div>
                      <div className='flex flex-row text-sm leading-none text-gray-600 mt-4'>
                        <p className='text-sm leading-none text-gray-600'>
                          Quantity:{' '}
                          <span className='text-gray-800'> {p.qty}</span>
                        </p>
                      </div>
                    </div>
                    <div className='flex mt-4 md:mt-0 md:justify-end items-center w-full '>
                      <span className='my-auto'>
                        <button
                          onClick={() => handleRemoveFromCart(p)}
                          type='button'
                          className='text-blue-900 bg-white hover:font-semibold text-sm lg:text-md font-semibold leading-5 lg:leading-6 focus:outline-none focus:ring-1 focus:ring-white rounded-lg'
                        >
                          Remove
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              <div className='flex flex-col justify-start items-start mt-8 xl:mt-10 space-y-10 w-full'>
                <div className='flex flex-col space-y-4 w-full'>
                  <div className='flex w-full justify-center items-center pt-1 md:pt-4  xl:pt-8 space-y-6 md:space-y-8 flex-col'>
                    <button
                      onClick={clicked}
                      className='py-5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800  w-full text-base font-medium leading-4 text-white bg-gray-800 hover:bg-black'
                    >
                      Submit to Quote
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {modalOn && (
        <QuotationForm
          setModalOn={setModalOn}
          setChoice={setChoiceOn}
          cart={cart}
          setErrorOn={setErrorOn}
        />
      )}
    </div>
  );
}

export default OrderSummary;
