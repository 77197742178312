import React from 'react';

const ImageWithDelete = ({imageUrl, onDelete}) => {
    return (

        <div className="w-24 h-24 cursor-pointer">
            <div className='relative bg-white'>
                <div className='flex'>
                    <img alt='' className='w-24 h-24 block mx-auto' src={imageUrl}/>
                    <a
                        onClick={onDelete}
                        className="-m-2 w-6 h-6 bg-red-500 flex hover:bg-red-600 text-white font-bold rounded-full no-underline rounded-full flex items-center justify-center absolute pin-t pin-r">
                        x
                    </a>
                </div>
            </div>
        </div>

    );
};

export default ImageWithDelete;