import React from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Status(props) {
  return (
    <div>
      <span
        className={classNames(
          props.status === 'pending'
            ? 'text-yellow-500 border-yellow-500'
            : props.status === 'sent'
            ? 'text-green-500 border-green-500'
            : props.status === 'no'
            ? 'text-red-500 border-red-500'
            : '',
          'text-xs font-medium px-2.5 py-0.5 rounded-full border',
        )}
      >
        {props.status}
      </span>
    </div>
  )
}

export default Status
