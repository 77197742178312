import React from 'react'
import { Link } from 'react-router-dom'

function PageNotFound() {
  return (
    <div className="bg-slate-100 h-screen">
      <div className="flex items-center justify-center py-12">
        <div className="bg-white border rounded-md flex items-center justify-center mx-4 md:w-2/3 ">
          <div className="flex flex-col items-center py-16 ">
            <p className="text-6xl md:text-7xl lg:text-9xl font-bold tracking-wider text-blue-900">
              404
            </p>
            <h1 className="px-4 pt-8 pb-4 text-center text-5xl font-bold leading-10 text-gray-800">
              OOPS!{' '}
            </h1>
            <p className="px-4 pb-10 text-base leading-none text-center text-gray-600">
              No signal here! we cannot find the page you are looking for{' '}
            </p>
            <button className="mx-4 h-10 w-44 border rounded-md text-white text-base bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-blue-800">
              <Link to="/">Go Back to Home</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound
