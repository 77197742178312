import React, { useRef, useState } from 'react';
import {
  PencilIcon,
  EyeIcon,
  TrashIcon,
  QrCodeIcon,
  ArrowDownTrayIcon,
  PrinterIcon,
  XMarkIcon,
  PaintBrushIcon,
} from '@heroicons/react/24/outline';

import Modal from './Modal';
import SlideOvers from './SlideOvers';

import ViewEditUser from '../../../Views/AdminFeatures/SlideOvers/Users/ViewEditUser';
import ViewEditProduct from '../../../Views/AdminFeatures/SlideOvers/Products/ViewEditProduct';
import ViewEditClient from '../../../Views/AdminFeatures/SlideOvers/Clients/ViewEditClient';
import ViewDeleteUser from '../../../Views/AdminFeatures/Modals/Users/ViewDeleteUser';
import ViewDeleteClients from '../../../Views/AdminFeatures/Modals/Clients/ViewDeleteClients';
import ViewDeleteProducts from '../../../Views/AdminFeatures/Modals/Products/ViewDeleteProducts';
import ViewDeleteFeedback from '../../../Views/AdminFeatures/Modals/Feedback/ViewDeleteFeedback';
import ViewQuatationDetails from '../../../Views/AdminFeatures/Modals/Quotation/ViewQuatationDetails';
import ViewDeleteQuotation from '../../../Views/AdminFeatures/Modals/Quotation/ViewDeleteQuotation';
import ViewUserDetails from '../../../Views/AdminFeatures/SlideOvers/Users/ViewUserDetails';
import ViewClientDetails from '../../../Views/AdminFeatures/SlideOvers/Clients/ViewClientDetails';
import ViewProduct from '../../../Views/AdminFeatures/SlideOvers/Products/ViewProduct';
import ViewFeedback from '../../../Views/AdminFeatures/SlideOvers/Feedback/ViewFeedback';
import ViewEditColorVarient from '../../../Views/AdminFeatures/Modals/Products/ViewEditColorVarient';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Actions(props) {
  const [showModal, setShowModal] = useState(false);
  const [viewUserDetailsSlide, setViewUserDetailsSlide] = useState(false);
  const [editUserDetailsSlide, setEditUserDetailsSlide] = useState(false);
  const [deleteUserDetailsModal, setDeleteUserDetailsModal] = useState(false);
  const [viewQuatiotionSlide, setViewQuatiotionSlide] = useState(false);
  const [deleteQuatiotionModal, setDeleteQuatiotionModal] = useState(false);
  const [viewClientDetailsSlide, setViewClientDetailsSlide] = useState(false);
  const [editClientDetailsSlide, setEditClientDetailsSlide] = useState(false);
  const [deleteClientDetailsModal, setDeleteClientDetailsModal] =
    useState(false);
  const [viewProductDetailsSlide, setViewProductDetailsSlide] = useState(false);
  const [editProductDetailsSlide, setEditProductDetailsSlide] = useState(false);
  const [deleteProductDetailsModal, setDeleteProductDetailsModal] =
    useState(false);
  const [editProductColorModal, setEditProductColorModal] = useState(false);
  const [viewFeedbackDetailsSlide, setViewFeedbackDetailsSlide] =
    useState(false);
  const [deleteFeedbackDetailsModal, setDeleteFeedbackDetailsModal] =
    useState(false);

  const [generated, setGenerated] = useState(false);
  const [productId, setProductId] = useState(props.productId);
  const [quotationName, setQuotationName] = useState(props.name);
  const [quotationStatus, setQuotationStatus] = useState(props.status);
  const [productQR, setProductQR] = useState(props.productQR);
  const [name, setItemName] = useState(props.name);

  const [userId, setUserId] = useState(props.userId);
  const [qutationId, setQuotationId] = useState(props.qutationId);
  const [clientId, setClientId] = useState(props.clientId);
  const [feedbackId, setfeedbackId] = useState(props.feedbackId);

  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState('');

  const handleQRCodeClicked = (id, productQR) => {
    setProductId(id);
    setProductQR(productQR);
    setShowModal(true);
  };
  const handleQRCodeColorSelected = (id) => {
    setFilter(
      props.colors[id].item_color.toUpperCase() +
        ' ' +
        props.colors[id].color_finish.toUpperCase()
    );
  };

  const handleQRDownload = (imageUrl, e) => {
    e.preventDefault();
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = `${productId}.jpg`;
    link.target = '_blank';
    link.rel = 'noopener noreferrer'; // Set the rel attribute for security purposes
    link.click();
  };

  const handlePrint = (imageUrl, id, name, color, e) => {
    e.preventDefault();
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
        <!DOCTYPE html>
        <html>
        <head>
          <title>Print Image</title>
          <style>
            @media print {
              @page {
                size: auto;
                margin: 0;
              }
              body {
                margin: 0;
                display: flex;
                float: left;
                width: 100%;
                flex-direction: column;
              }
              .main-container {
                width: 100%;
                display: flex;
                flex-direction: column;
              
              }
              .image-container, .table-container {
                width: 100%;
                display: flex;
                flex-direction: column;
         
              }
              .image {
                width: 40%;
                height: auto;
              }
              .table-container {
                margin-top: -25px;
              }
              table {
                border-collapse: collapse;
                width: 100%;
              }
              th, td {
                padding-left: 50px;
                text-align: left;
              }
            }
          </style>
        </head>
        <body>
          <div class="main-container">
            <div class="image-container">
              <img class="image" src="${imageUrl}" alt="{productId}">
            </div>
            <div class="table-container">
              <table>
                <tr>
                  <td>${id ? id : ''}&nbsp;&nbsp;${name ? name : ''}</td>
                </tr>
                <tr>
                  <td>${color ? color : ''}</td>
                </tr>
              </table>
            </div>
          </div>
          <script type="text/javascript">
            window.onload = function() {
              window.print();
            };
            window.onafterprint = function() {
              window.close();
            };
          </script>
        </body>
        </html>
    `);
    printWindow.document.close();
  };

  const viewUserDetails = (id) => {
    setUserId(id);
    setViewUserDetailsSlide(true);
  };
  const editUserDetails = (id) => {
    setUserId(id);
    setEditUserDetailsSlide(true);
  };

  const handleClose = () => {
    setEditUserDetailsSlide(false);
    setViewUserDetailsSlide(false);
    setDeleteUserDetailsModal(false);

    setViewQuatiotionSlide(false);
    setDeleteQuatiotionModal(false);

    setEditProductDetailsSlide(false);
    setViewProductDetailsSlide(false);
    setDeleteProductDetailsModal(false);

    setEditClientDetailsSlide(false);
    setViewClientDetailsSlide(false);
    setDeleteClientDetailsModal(false);

    setViewFeedbackDetailsSlide(false);
    setDeleteFeedbackDetailsModal(false);
    setEditProductColorModal(false);
  };
  const handleUpdate = (formData) => {
    // Handle the update action with the submitted form data
  };

  const deleteUserDetails = (id) => {
    setUserId(id);
    setDeleteUserDetailsModal(true);
  };

  const viewQuatiotion = (id, name, status) => {
    setQuotationId(id);
    setQuotationName(name);
    setQuotationStatus(status);
    setViewQuatiotionSlide(true);
  };

  const viewClientDetails = (id) => {
    setClientId(id);
    setViewClientDetailsSlide(true);
  };
  const editClientDetails = (id) => {
    setClientId(id);
    setEditClientDetailsSlide(true);
  };

  const deleteClientDetails = (id) => {
    setClientId(id);
    setDeleteClientDetailsModal(true);
  };

  const viewProductDetails = (id) => {
    setProductId(id);
    setViewProductDetailsSlide(true);
  };
  const editProductDetails = (id) => {
    setProductId(id);
    setEditProductDetailsSlide(true);
  };
  const deleteProductDetails = (id) => {
    setProductId(id);
    setItemName(props.name);

    setDeleteProductDetailsModal(true);
  };
  const editProductColor = (id) => {
    setProductId(id);
    setItemName(props.name);

    setEditProductColorModal(true);
  };

  const viewFeedbackDetails = (id) => {
    setfeedbackId(id);
    setViewFeedbackDetailsSlide(true);
  };
  const deleteFeedbackDetails = (id) => {
    setfeedbackId(id);
    setDeleteFeedbackDetailsModal(true);
  };

  return (
    <>
      <div class='flex items-center gap-x-4'>
        {props.selectedTab === 1 ? (
          <>
            <button
              className={classNames(
                props.selectedTab === 5 ? 'hidden' : 'relative',
                'text-gray-500 transition-colors duration-200 focus:outline-none'
              )}
              onClick={() => editUserDetails(props.userId)}
            >
              <PencilIcon className='h-5 w-5 text-blue-600 hover:text-blue-900' />
            </button>

            <button
              class='text-gray-500 transition-colors duration-200 focus:outline-none'
              onClick={() => viewUserDetails(props.userId)}
            >
              <EyeIcon className='h-5 w-5 text-blue-600 hover:text-blue-900' />
            </button>

            <button
              class='text-gray-500 transition-colors duration-200 focus:outline-none'
              onClick={() => deleteUserDetails(props.userId)}
            >
              <TrashIcon className='h-5 w-5 text-blue-600' />
            </button>
          </>
        ) : props.selectedTab === 2 ? (
          <>
            <button
              class='text-gray-500 transition-colors duration-200 focus:outline-none'
              onClick={() =>
                viewQuatiotion(props.qutationId, props.name, props.status)
              }
            >
              <EyeIcon className='h-5 w-5 text-blue-600 hover:text-blue-900' />
            </button>
          </>
        ) : props.selectedTab === 3 ? (
          <>
            <button onClick={() => editClientDetails(props.clientId)}>
              <PencilIcon className='h-5 w-5 text-blue-600 hover:text-blue-900' />
            </button>

            <button
              class='text-gray-500 transition-colors duration-200 focus:outline-none'
              onClick={() => viewClientDetails(props.clientId)}
            >
              <EyeIcon className='h-5 w-5 text-blue-600 hover:text-blue-900' />
            </button>

            <button
              class='text-gray-500 transition-colors duration-200 focus:outline-none'
              onClick={() => deleteClientDetails(props.clientId)}
            >
              <TrashIcon className='h-5 w-5 text-blue-600' />
            </button>
          </>
        ) : props.selectedTab === 4 ? (
          <>
            <button onClick={() => editProductDetails(props.productId)}>
              <PencilIcon className='h-5 w-5 text-blue-600 hover:text-blue-900' />
            </button>

            <button
              class='text-gray-500 transition-colors duration-200 focus:outline-none'
              onClick={() => viewProductDetails(props.productId)}
            >
              <EyeIcon className='h-5 w-5 text-blue-600 hover:text-blue-900' />
            </button>

            <button
              class='text-gray-500 transition-colors duration-200 focus:outline-none'
              onClick={() => deleteProductDetails(props.productId)}
            >
              <TrashIcon className='h-5 w-5 text-blue-600' />
            </button>
            <button onClick={() => editProductColor(props.productId)}>
              <PaintBrushIcon className='h-5 w-5 text-blue-600 hover:text-blue-900' />
            </button>
          </>
        ) : props.selectedTab === 5 ? (
          <>
            <button
              class='text-gray-500 transition-colors duration-200 focus:outline-none'
              onClick={() => viewFeedbackDetails(props.feedbackId)}
            >
              <EyeIcon className='h-5 w-5 text-blue-600 hover:text-blue-900' />
            </button>

            <button
              class='text-gray-500 transition-colors duration-200 focus:outline-none'
              onClick={() => deleteFeedbackDetails(props.feedbackId)}
            >
              <TrashIcon className='h-5 w-5 text-blue-600' />
            </button>
          </>
        ) : props.selectedTab === 6 ? (
          <button
            class='text-gray-500 transition-colors duration-200 focus:outline-none'
            onClick={() =>
              handleQRCodeClicked(props.productId, props.productQR)
            }
          >
            <QrCodeIcon className='h-5 w-5 text-blue-600 hover:text-blue-900' />
          </button>
        ) : props.selectedTab === 7 ? (
          <>
            <button
              className={classNames(
                props.selectedTab === 5 ? 'hidden' : 'relative',
                'text-gray-500 transition-colors duration-200 focus:outline-none'
              )}
              onClick={() => editUserDetails(props.userId)}
            >
              <PencilIcon className='h-5 w-5 text-blue-600 hover:text-blue-900' />
            </button>

            <button
              class='text-gray-500 transition-colors duration-200 focus:outline-none'
              onClick={() => viewUserDetails(props.userId)}
            >
              <EyeIcon className='h-5 w-5 text-blue-600 hover:text-blue-900' />
            </button>

            <button
              class='text-gray-500 transition-colors duration-200 focus:outline-none'
              onClick={() => deleteUserDetails(props.userId)}
            >
              <TrashIcon className='h-5 w-5 text-blue-600' />
            </button>
          </>
        ) : null}
      </div>
      {showModal && (
        <Modal
          content={
            <div>
              <form>
                <div
                  className='flex justify-end text-gray-400 hover:text-gray-600'
                  onClick={() => setShowModal(false)}
                >
                  <XMarkIcon className='h-6 w-6 text-gray-500 cursor-pointer' />
                </div>
                <div className='relative flex justify-center mt-8'>
                  <input
                    type='text'
                    id='search'
                    className='lg:w-3/4 md:w-3/4 w-w-3/4 lg:h-12 md:h-12 sm:h-10 h-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-1 focus:outline-none focus:ring-blue-500 focus:border-blue-500 block p-2.5 my-auto'
                    value={
                      `${process.env.REACT_APP_PRODUCT_SHOWCASE_URL}` +
                      props.productId
                    }
                    disabled
                  />
                  <button
                    type='button'
                    className='relative ml-4 py-2 px-2 lg:w-1/4 md:w-1/4 w-full bg-blue-800 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center lg:text-base md:text-base sm:text-sm text-xs shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg'
                    onClick={() => {
                      setGenerated(true);
                      handleQRCodeColorSelected(0);
                    }}
                  >
                    Generate QR
                  </button>
                </div>
                {generated ? (
                  <>
                    <div className='flex justify-start'>
                      <div className='relative mr-12 mt-4'>
                        <div
                          className='bg-white flex justify-between border rounded w-auto px-2'
                          onClick={() => setShow(!show)}
                        >
                          <p className='px-2 py-2 text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs font-normal'>
                            {filter}
                          </p>
                          <div className='bg-white items-center flex '>
                            <div className='cursor-pointer text-gray-600'>
                              {show ? (
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 24 24'
                                  stroke-width='1.5'
                                  stroke='currentColor'
                                  className='lg:w-6 md:w-6 sm:w-4 w-4 lg:h-6 md:h-6 sm:h-4 h-4'
                                >
                                  <path
                                    stroke-linecap='round'
                                    stroke-linejoin='round'
                                    d='M4.5 15.75l7.5-7.5 7.5 7.5'
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 24 24'
                                  stroke-width='1.5'
                                  stroke='currentColor'
                                  className='lg:w-6 md:w-6 sm:w-4 w-4 lg:h-6 md:h-6 sm:h-4 h-4'
                                >
                                  <path
                                    stroke-linecap='round'
                                    stroke-linejoin='round'
                                    d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                                  />
                                </svg>
                              )}
                            </div>
                          </div>
                        </div>
                        {show && (
                          <ul className='visible transition duration-300 opacity-100 bg-white shadow rounded mt-2 py-1 w-auto absolute cursor-pointer z-10'>
                            {props.colors.map((c, index) => (
                              <li
                                className='cursor-pointer text-gray-600 lg:text-sm md:text-sm sm:text-xs text-xs leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center'
                                onClick={() => {
                                  handleQRCodeColorSelected(index);
                                  setShow(false);
                                }}
                              >
                                {c.item_color.toUpperCase() +
                                  ' ' +
                                  c.color_finish.toUpperCase()}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>

                    <div className=' flex items-center justify-center'>
                      <div className='w-80 bg-white p-4 '>
                        <img
                          src={productQR.toString()}
                          alt='Image'
                          className='w-full h-auto rounded-md'
                        />
                        <div
                          className='mt-2 h-14 overflow-hidden'
                          style={{ marginTop: '-20px', paddingLeft: '20%' }}
                        >
                          <p className='text-gray-700 text-sm leading-tight truncate-2-lines'>
                            {productId}&nbsp;&nbsp;
                            {props.name ? props.name : ''}
                          </p>
                          <p className='text-gray-700 text-sm leading-tight '>
                            {filter}
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className='flex justify-center space-x-4 mt-6'>
                      <button
                        type='submit'
                        onClick={(e) => handleQRDownload(productQR, e)}
                        className='lg:1/6 md:w-1/4 sm:w-1/2 w-1/2 px-4 lg:py-3 md:py-3 sm:py-2 py-2 leading-5  border border-blue-800 text-blue-800 hover:text-white transition-colors duration-200 transform bg-white hover:bg-blue-800 rounded-lg focus:outline-none '
                      >
                        <div className='flex justify-center space-x-2'>
                          <ArrowDownTrayIcon className='lg:h-6 h-5 lg:w-6 w-5' />
                          <span>Download</span>
                        </div>
                      </button>
                      <button
                        type='submit'
                        onClick={(e) =>
                          handlePrint(
                            productQR,
                            productId,
                            props.name,
                            filter,
                            e
                          )
                        }
                        className='lg:1/6 md:w-1/4 sm:w-1/2 w-1/2 px-4 lg:py-3 md:py-3 sm:py-2 py-2 leading-5  border border-blue-800 text-blue-800 hover:text-white transition-colors duration-200 transform bg-white hover:bg-blue-800 rounded-lg focus:outline-none '
                      >
                        <div className='flex justify-center space-x-2'>
                          <PrinterIcon className='g:h-6 h-5 lg:w-6 w-5' />
                          <span>Print</span>
                        </div>
                      </button>
                    </div>
                  </>
                ) : null}
              </form>
            </div>
          }
        />
      )}
      {editUserDetailsSlide && (
        <SlideOvers
          content={
            <ViewEditUser
              userId={userId}
              onClose={handleClose}
              onUpdate={handleUpdate}
            />
          }
        />
      )}
      {viewUserDetailsSlide && (
        <SlideOvers
          content={<ViewUserDetails userId={userId} onClose={handleClose} />}
        />
      )}
      {deleteUserDetailsModal && (
        <Modal
          content={<ViewDeleteUser userId={userId} onClose={handleClose} />}
        />
      )}
      {viewQuatiotionSlide && (
        <Modal
          content={
            <ViewQuatationDetails
              onClose={handleClose}
              qutationId={qutationId}
              name={quotationName}
              status={quotationStatus}
            />
          }
        />
      )}

      {deleteQuatiotionModal && (
        <Modal content={<ViewDeleteQuotation onClose={handleClose} />} />
      )}
      {editClientDetailsSlide && (
        <SlideOvers
          content={
            <ViewEditClient
              clientId={clientId}
              onClose={handleClose}
              onUpdate={handleUpdate}
            />
          }
        />
      )}
      {viewClientDetailsSlide && (
        <SlideOvers
          content={
            <ViewClientDetails clientId={clientId} onClose={handleClose} />
          }
        />
      )}
      {deleteClientDetailsModal && (
        <Modal
          content={
            <ViewDeleteClients clientId={clientId} onClose={handleClose} />
          }
        />
      )}
      {editProductDetailsSlide && (
        <SlideOvers
          content={
            <ViewEditProduct
              productId={productId}
              onClose={handleClose}
              onUpdate={handleUpdate}
            />
          }
        />
      )}
      {viewProductDetailsSlide && (
        <SlideOvers
          content={<ViewProduct productId={productId} onClose={handleClose} />}
        />
      )}
      {deleteProductDetailsModal && (
        <Modal
          content={
            <ViewDeleteProducts
              productId={productId}
              name={name}
              onClose={handleClose}
            />
          }
        />
      )}
      {editProductColorModal && (
        <Modal
          content={
            <ViewEditColorVarient
              productId={productId}
              name={name}
              onClose={handleClose}
            />
          }
        />
      )}
      {viewFeedbackDetailsSlide && (
        <SlideOvers
          content={
            <ViewFeedback onClose={handleClose} feedbackId={feedbackId} />
          }
        />
      )}
      {deleteFeedbackDetailsModal && (
        <Modal
          content={
            <ViewDeleteFeedback
              onClose={handleClose}
              feedbackId={feedbackId}
              name={name}
            />
          }
        />
      )}
    </>
  );
}

export default Actions;
