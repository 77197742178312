import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Actions from '../../components/Admin/Content/Actions';
import Status from '../../components/Admin/Content/Status';
import Dashboard from '../../components/Admin/Dashboard/Dashboard';
import { useQuotationContext } from '../../context/QuotationContex';

function ViewAdminQuotations() {
  const { quotations, getAllQuotations } = useQuotationContext();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getAllQuotations();
    setTableData(quotations);
  }, []);

  useEffect(() => {
    setTableData(quotations);
  }, [quotations]);

  function handleSearch(e) {
    if (e.target.value === '') {
      getAllQuotations();
    }

    const filteredData = quotations.filter((row) => {
      if (row.companyName) {
        return row['companyName']
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      }
    });
    setTableData(filteredData);
  }

  const columns = [
    { name: 'QUOTATION ID', selector: 'quotationNumber' },
    { name: 'DATE', selector: 'quotationReceivedDate' },
    { name: 'COMPANY NAME', selector: 'companyName' },
    { name: 'SHIPPING FROM', selector: 'shippingFrom' },
    { name: 'TOTAL NO. OF PCS.', selector: 'num_of_pcs' },
    { name: 'TOTAL INVOICE VALUE', selector: 'invoice_value' },
    { name: 'STATUS', selector: 'quoteStatus' },
    { name: 'ACTIONS', selector: 'actions' },
  ];

  return (
    <div>
      <Dashboard
        tab={2}
        title='Quotations'
        search='Search quotations...'
        columns={columns}
        data={tableData}
        handleSearch={handleSearch}
      />
    </div>
  );
}

export default ViewAdminQuotations;
